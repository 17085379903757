import React from "react";
import logo from "./logo.svg";
import Login from "./pages/manage/login";
import Upload from "./pages/manage/upload";
import Dashbord from "./pages/manage/dashboard";
import Home from "./pages/home";
import Search from "./pages/search";
import Register from "./pages/manage/register";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
import Show from "./pages/show";
import Contact from "./pages/contact";
import Feedback from './pages/feedback';
import Request from './pages/request';

function App() {
    return (
        <div className="App">
            <Router>
                <AnimatedSwitch
                    atEnter={{ opacity: 0 }}
                    atLeave={{ opacity: 0 }}
                    atActive={{ opacity: 1 }}
                    className="switch-wrapper" 
                >
                    <Route exact path="/" component={Home} />
                    <Route exact path="/show/:imageId" component={Show} />
                    <Route exact path="/search/:searchQ" component={Search} />
                    <Route exact path="/search" component={Search} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/feedback" component={Feedback} />
                    <Route exact path="/request" component={Request} />

                    <Route path="/manage/register" component={Register} />    
                    <Route path="/manage/login" component={Login} />
                    <Route path="/manage/upload" component={Upload} />
                    <Route path="/manage/edit/:collectionId" component={Upload} />

                    <Route path="/manage" component={Dashbord} />
                </AnimatedSwitch>
            </Router>
        </div>
    );
}

export default App;
