import React, { Component } from "react";
import Header from "../components/header";
import ImageList from "../components/imageList";
import Database from "../src/database";
import Files from "../src/files";
import Helmet from "react-helmet";
import LoadingScreen from "../src/loading";
import Alert from "../src/alert";

import Footer from "../components/footer";

class Show extends Component {
  state = {
    image: "",
    title: "",
    moreImages: [],
    info: "",
    backgroundColor: "rgb(0, 18, 41)",
    backgroundColors: [],
    collectionId: "",
    similarSearchBy: "",
    corps: "",
    who: "",
    hideImage: true,
    showImage: false,
    update: 0,
  };

  constructor(props) {
    super(props);

    this.changeAllColors = this.changeAllColors.bind(this);
    this.revertAllColors = this.revertAllColors.bind(this);
  }

  componentDidMount() {
    const load = new LoadingScreen();
    load.startLoading();
    const db = new Database();
    const query = { id: this.props.match.params.imageId };
    db.get("images", query).then((data) => {
        console.log(data);
        if (typeof data[0].title !== "undefined")
          this.setState({ title: data[0].title });

        if (typeof data[0].keywords !== "undefined")
          this.setState({ similarSearchBy: data[0].keywords.join(", ") });
        else this.setState({ similarSearchBy: data[0].title });
        this.setState({ corps: data[0].corps });
        this.setState({ who: data[0].who });
        const file = new Files();
        const imageLink = data[0].cover.url;

        this.setState({ image: imageLink });
        this.getImageColors(imageLink);
        this.setState({ collectionId: data[0].collectionId });
        db.get("images", { collectionId: data[0].collectionId }).then((collectionData) => {
            let moreImages = [];
            collectionData.forEach((cd) => {
                if (cd.id != this.props.match.params.imageId) {
                    load.stopLoading();
                    moreImages.push({
                        thumbnail: cd.thumbnail.url,
                        title: cd.title,
                        id: cd.id,
                        fullImage: cd.image.url,
                        cover: cd.cover.url,
                    });
                    this.setState({moreImages, update: this.state.update++ });
                }

            }).catch(() => {
                load.stopLoading();
            });
        }).catch(() => {
            load.stopLoading();
        });
      })
      .catch(() => {
        load.stopLoading();
      });
  }

  changeAllColors(color) {
    this.setState({ backgroundColor: color });
  }
  revertAllColors() {
    this.setState({ backgroundColor: "rgb(0, 18, 41)" });
  }

  getImageColors(image) {
    const file = new Files();
    file.getImageColors(image).then((colors) => {
      colors.forEach((col) => {
        this.state.backgroundColors.push(col);
        this.setState({ update: this.state.update++ });
      });
    });
  }

  imageLoaded() {
    this.setState({ hideImage: false }, () => {
      setTimeout(() => {
        this.setState({ showImage: true });
      }, 2000);
    });
  }

  render() {
    return (
        <div className='show-container'>
            <React.Fragment>
                <Helmet>

                <title>המאגר | {this.state.title}</title>
                <meta
                    property="og:title"
                    content={`${this.state.title} -  המאגר `}
                />
                <meta
                    property="og:description"
                    content={`צפו ב${this.state.title} ועוד תמונות מהמאגר`}
                />
                <meta property="og:image" content={this.state.image} />
                </Helmet>

                <Header
                small={true}
                backgroundColor={this.state.backgroundColor}
                title=""
                link={true}
                customLink="../../"
                backgroundImage={false}
                noLogo={true}
                back={true}
                />

                <div
                style={{ backgroundColor: this.state.backgroundColor }}
                className={`image-area ${this.state.image == "" ? "hide" : ""}`}
                >
                <div>
                    <img
                    src={this.state.image}
                    alt={this.state.title}
                    className={`background-image`}
                    />
                </div>
                <img
                    onLoad={() => {
                    this.imageLoaded();
                    }}
                    src={this.state.image}
                    alt={this.state.title}
                    className={`main-img`}
                />
                <br />
                <div className="image-name">{this.state.title}</div>
                <a
                    className={`download${this.state.image != "" ? "" : " hide"}`}
                    href={this.state.image}
                    download={this.state.title}
                >
                    <button>הורדת התמונה</button>
                </a>
                </div>

                <div
                className={`home-title`}
                style={{ backgroundColor: this.state.backgroundColor }}
                >
                תמונות קשורות
                </div>
                <div
                className={this.state.moreImages.length === 0 ? "hide" : ""}
                style={{ marginTop: "30px" }}
                >
                <ImageList
                    small={true}
                    title={false}
                    collectImages={false}
                    images={this.state.moreImages}
                    onImageClick="/show"
                    itemIndex={0}
                    show={true}
                ></ImageList>
                </div>
                <div
                className={`home-title`}
                style={{ backgroundColor: this.state.backgroundColor }}
                >
                צבעים דומיננטים
                </div>

                <div
                className={`lds-ellipsis dark${
                    this.state.backgroundColors.length === 0 ? "" : " hide"
                }`}
                >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                </div>
                <div className="dominant-colors-area">
                {this.state.backgroundColors.map((singleColor, i) => (
                    <React.Fragment key={i}>
                    <div className="color">
                        <div
                        className={`dominant-colors${i === 0 ? " first" : ""}${
                            i === this.state.backgroundColors.length - 1 ? " last" : ""
                        }`}
                        style={{
                            backgroundColor: singleColor,
                            width: `calc(80vw / ${this.state.backgroundColors.length})`,
                        }}
                        onMouseEnter={(e) => {
                            this.changeAllColors(singleColor);
                        }}
                        onMouseLeave={(e) => {
                            this.revertAllColors();
                        }}
                        ></div>
                        <div className="dominant-color-text">{singleColor}</div>
                    </div>
                    </React.Fragment>
                ))}
                </div>

                <Footer backgroundColor={this.state.backgroundColor}></Footer>
                {/* <ImageList collectImages={true} limit={10} search={this.state.similarSearchBy} notFromCollection={this.state.collectionId} onImageClick=".."></ImageList> */}
            </React.Fragment>
        </div>
    );
  }
}

export default Show;
