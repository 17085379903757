let modalId = new Date().getTime();
class Confirm {
    defaultOptions = { yes: 'כן', no: 'לא' };
    saveImagesOptions = undefined;
    title = '';
    content = '';
    dontHIde = false;
    disable = false;
    constructor(title, content, saveImagesOptions, dontHide, disable) {
        this.title = title;
        this.content = content;
        this.saveImagesOptions = saveImagesOptions;
        this.dontHide = dontHide;
        this.disable = disable;
    }
    state = {
        modal: '',
    }

    show(options = this.saveImagesOptions ? this.saveImagesOptions : this.defaultOptions) {
        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }
        let currentOptions = { ...this.defaultOptions, ...options };
        return new Promise((complete) => {
            const rootElememt = document.querySelector('#root');
            const modal = document.createElement('div');
            modal.setAttribute('id', `alert`);
            modal.classList.add('alert');

            modal.innerHTML = `<div class="black"></div><div class="modal"><div class="title">${this.title}</div><div class="content">${this.content}</div><div class='confirm-buttons'><button id="confirm-btn-yes">${currentOptions.yes}</button><button id="confirm-btn-no">${currentOptions.no}</button></div></div>`;
            modal.querySelector('#confirm-btn-no').addEventListener('click', (element) => {
                this.hide(modal, complete, false);
            })
            modal.querySelector('#confirm-btn-yes').addEventListener('click', (element) => {
                this.hide(modal, complete, true);
            })
            setTimeout(() => {
                modal.classList.add('show');
            }, 20);

            rootElememt.append(modal);
        });
    }
    hide = (modal, complete, boolean) => {
        if (boolean || this.dontHide) {
            document.querySelector('.modal .title').innerHTML = 'טוען...';
            document.querySelector('.modal .content').innerHTML = '';
            document.querySelectorAll('.modal button').forEach(button => {
                button.classList.add('disabled');
            })
            complete(boolean);
        } else {
            modal.classList.remove('show');
            setTimeout(() => {
                console.log(modal);
                modal.remove();
            }, 600);
            complete(boolean);
        }
    }
}

export default Confirm;