import React, { Component } from "react";
import Header from "../../components/header";
import UploadForm from "../../components/newUploadForm";
import Database from "../../src/database";
import Alert from "../../src/alert";
import LoadingScreen from "../../src/loading";
import Auth from '../../src/auth';

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
        name: '',
        backgroundColor: "rgb(0, 18, 41)",
        collection: "",
        edit: false,
        title: "העלאת תמונה חדשה",
        collectionId: 0,
        newUpload: false,
    };
  }
  getInformation = () => {
     if (typeof this.props.match.params.collectionId !== "undefined") {
      this.setState({ edit: true }, () => {
        this.setState({ title: "עריכת תמונה" });
        this.setState({ collectionId: this.props.match.params.collectionId });
      });
    } else {
        this.setState({newUpload: true})
    }
    if (typeof this.props.match.params.id !== "undefined") {
      const db = new Database();
      db.get("images", { id: this.props.match.params.id }).then((image) => {
          console.log(image);
        this.setState({ collection: image[0].collectionId });
      });
    }
  }
    componentDidMount() {
        this.getInformation();
    }

  render() {
    return (
      <React.Fragment>
        <Header
          title={this.state.title}
          backgroundImage={false}
          small={true}
          backgroundColor={this.state.backgroundColor}
          customLink={this.state.edit ? "../../manage" : "../manage"}
          noLogo={true}
          back={true}
          backTo='לוח ניהול'
          backToHref='/manage'
        />
        <UploadForm
          collection={this.state.collection}
          edit={this.state.edit}
          collectionId={this.state.collectionId}
          getInformation={this.getInformation}
          newUpload={this.state.newUpload}
        ></UploadForm>
      </React.Fragment>
    );
  }
}

export default Upload;
