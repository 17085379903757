import React, { Component } from 'react';
import Database from '../src/database';
import Files from '../src/files';
import Alert from '../src/alert';
import { getByAltText } from '@testing-library/react';
import { Link } from 'react-router-dom';
import Redirect from '../src/redirect';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { rotate } from 'jimp';


class ImageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collections: [],
            currentImage: [],
            images: undefined,
            customImageList: false,
            onIndexChanged: undefined,
            query: '',
            update: 0,
            limit: 0,
            collectImages: true,
            searching: false,
            search: '',
            title: true,
            linkToAll: false,
            small: false,
            big: false,
            showHidden: false,
            showInfo: false,
            previewImage: '',
            fullImage: '',
            previewTitle: '',
            loadingText: 'טוען...',
            previewImageId: '',
            preview: false,
            hidePreview: false,
            countSearch: true,
            ...this.props
        }

        this.getAllImages = this.getAllImages.bind(this);
        this.addImageToArray = this.addImageToArray.bind(this);
        this.showNextImage = this.showNextImage.bind(this);
        this.showPreviuosImage = this.showPreviuosImage.bind(this);
        this.removeDuplicates = this.removeDuplicates.bind(this);
        this.showPreview = this.showPreview.bind(this);
        this.hidePreview = this.hidePreview.bind(this);
    }

    pNewLimit = 0;

    componentDidMount() {
        if (this.props.manage) {
            this.getCollections();
        } else {   
            this.getAllImages();
        }
    }
    //get all image collections
    getCollections = () => {  
        this.setState({collections: []});
        this.removeDuplicates();
        this.setState({ searching: true });

        const db = new Database();
        db.get('collections').then(collections => {
            collections.forEach((collection, index) => {
                if (collection.id) {
                    db.get('images', {collectionId: collection.id}, 1).then(result => {
                        if (result.length > 0) {
                            const image = result[0]
                            this.addImageToArray(image.collectionId, {
                                thumbnail: image.thumbnail.url,
                                title: image.title,
                                id: image.id,
                                count: 1,
                                fullImage: image.image.url, 
                                cover: image.cover.url,
                                visible: image.visible,
                                rotate: image.rotate,
                                who: image.who, 
                                corps: image.corps,
                                keywords: image.keywords,
                            });
                        }
                    })
                }
            })
            this.setState({ searching: false });
        })
        db.get('images').then(images => {
            if (this.props.updateImageCount) {
                this.props.updateImageCount(images.length);
            }
        })
    }
    //Displaying all the images
    getAllImages() {
        //reseting the collections
        this.setState({collections: []});
        this.removeDuplicates();
        //Load images from props
        if (typeof this.state.images !== 'undefined') {
            this.loadImagesFromProps();
            return;
        }

        if (this.state.customImageList) return;

        //hide the searching text
        this.setState({ searching: true });

        if (this.state.search) {
            this.search(this.state.search);
            return;
        }
        //get the images from the Database
        const db = new Database();
        let newLimit = 0;
        if (this.state.limit == 'screen') {
            if (window.innerWidth >= 740)
                newLimit = Math.floor(window.innerWidth / 270) * 2;
            else
                newLimit = 4;
        }
        this.pNewLimit = newLimit;
        db.get('images', this.state.query, this.pNewLimit).then(images => {
            // console.log(this.state.query)
            // console.log(this.pNewLimit);
            let publicImages = 0;
            if (this.props.updateImageCount) {
                this.props.updateImageCount(images.length);
            }
            console.log(images);
            //Images recived as array
            images.forEach((image, index) => {
                console.log(index);
                if (image.visible) {
                    publicImages++;
                }
                if (!this.state.showHidden) {
                    if (!image.visible) {
                        return;
                    }
                }
                //hide the searching text
                if (this.state.searching) {
                    this.setState({ searching: false });

                }

                //Get the image url
                const files = new Files();
                //function for if some urls containe spaces: 

                    // if (cover.includes(' ')) {console.log(index, cover)}
                    // if (cover.includes(' ')) {
                    //     let url = encodeURI(cover);
                    //     const coverObject = image.cover;
                    //     coverObject.url = url;
                    //     db.update('images', {id: image.id}, {cover: coverObject}).then(result => {
                    //         console.log(result);
                    //     })
                    // }

                // Add the image to the collections array
                console.log(image.rotate);
                this.addImageToArray(image.collectionId, {
                    thumbnail: image.thumbnail.url,
                    title: image.title,
                    id: image.id,
                    count: 1,
                    fullImage: image.image.url, 
                    cover: image.cover.url,
                    visible: image.visible,
                    rotate: image.rotate,
                    who: image.who, 
                    corps: image.corps,
                    keywords: image.keywords,
                });

                // this.setState({ update: this.state.update++ });

            })
            if (this.props.updatePublicImageCount) {
                this.props.updatePublicImageCount(publicImages);
            }
            //and then 

        }).catch(() => {
            //hide the searching text
            this.setState({ searching: false });

        })
    }
    //Load images from props
    loadImagesFromProps() {
        let counter = 1;
        this.state.images.forEach(image => {
            this.addImageToArray(0, {
                thumbnail: image.thumbnail, 
                title: image.title, 
                id: image.id, 
                count: 1, 
                fullImage: image.fullImage, 
                cover: image.cover, 
                visible: image.visible, 
                rotate: image.rotate,
                who: image.who, 
                corps: image.corps,
                keywords: image.keywords,
            });
            counter++;
        })
    }
    //Adding the image to the array where the collection id is the same as recived
    addImageToArray(id, value) {
        let filter = false;
        //count the results to show the most relevant images
        if (window.location.search.length !== 0) {
            if (this.props.advancedProps.including) {
                let including = this.props.advancedProps.including;
                if (including.includes(',')) {
                    including = including.split(',').map(item => item.trim())
                    including.forEach(keyword => {
                        const string = `${value.who.toString()}, ${value.corps.toString()}, ${value.keywords.toString()}`;
                        if (!string.includes(keyword)) {
                            filter = true;
                        }
                    })
                } else {
                    const string = `${value.who.toString()}, ${value.corps.toString()}, ${value.keywords.toString()}`;
                    if (!string.includes(including)) {
                        filter = true;
                    }
                }
            }
            if (this.props.advancedProps.notincluding) {
                let notincluding = this.props.advancedProps.notincluding;
                if (notincluding.includes(',')) {
                    notincluding = notincluding.split(',').map(item => item.trim())
                    notincluding.forEach(keyword => {
                        const string = `${value.who.toString()}, ${value.corps.toString()}, ${value.keywords.toString()}`;
                        if (string.includes(keyword)) {
                            filter = true;
                        }
                    })
                } else {
                    const string = `${value.who.toString()}, ${value.corps.toString()}, ${value.keywords.toString()}`;
                    if (string.includes(notincluding)) {
                        filter = true;
                    }
                }
            }
        }
        if (filter) {return};
        this.state.collections.forEach(collection => {
            const index = collection.images.findIndex(findImage => findImage.id === value.id);
            if (index !== -1) {
                collection.images[index].count++;
            }
        })
        //this counter is used to check if collection with the recived id is found
        let newCollection = true;
        //Run through the collections
        if (this.state.collectImages) {
            this.state.collections.forEach(collection => {
                if (collection.id == id) {
                    collection.images.push(value);
                    newCollection = false
                    return;
                }
            })
            // if no collection is found
            if (newCollection) {
                this.state.currentImage[id] = 0;
                let collections = [...this.state.collections];
                collections.push({id, images: [value]});
                this.setState({collections}, () => {
                    if (this.props.updateCollectionCount) {
                        this.props.updateCollectionCount(collections.length);
                    }
                })
            }
        }
        else {
            this.state.currentImage[id] = 0;
            let collections = [...this.state.collections];
            collections.push({id, images: [value]});
            this.setState({collections}, () => {
                if (this.props.updateCollectionCount) {
                    this.props.updateCollectionCount(collections.length);
                }
            })
            this.state.collections.push({ id, images: [value] });
        }
        //Somtimes images are shown twice, this function deletes duplicates
        this.removeDuplicates();
        
    }
    //Somtimes images are shown twice, this function deletes duplicates
    removeDuplicates() {
        if (this.state.collectImages) {
            this.state.collections.forEach(collection => {
                collection.images = collection.images.filter((image, index, self) =>
                    index === self.findIndex(findImage => findImage.id === image.id)
                )
            })
            this.showMostResults();
        } else {
            let tempcol = this.state.collections.filter((collection, index, self) =>
                index === self.findIndex(findCollection => collection.images[0].id === findCollection.images[0].id)
            )
            this.setState({ collections: tempcol }, () => {
                this.showMostResults();
            });
        }


    }
    showMostResults() {
        const collectionsCount = [];
        this.state.collections.forEach(collection => {
            collection.images.forEach(image => {
                if (typeof collectionsCount[collection.id] === 'undefined' || collectionsCount[collection.id] < image.count) {
                    collectionsCount[collection.id] = image.count;
                    collection.count = image.count;
                }
            })
        })
        this.state.collections.sort(this.compare);
    }
    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const countA = a.count;
        const countB = b.count;

        let comparison = 0;
        if (countA > countB) {
            comparison = 1;
        } else if (countA < countB) {
            comparison = -1;
        }
        return comparison * -1;
    }
    //Showing the next image when the right arrow is clicked
    showNextImage(collection, element) {
        const max = collection.images.length - 1;
        if (this.state.currentImage[collection.id] >= max) {
            this.state.currentImage[collection.id] = 0;
        } else {
            this.state.currentImage[collection.id]++;
        }
        this.setState({ update: this.state.update++ });
        this.updateShownImage(collection, element);
        if (typeof this.state.onIndexChanged !== 'undefined') {
            this.state.onIndexChanged(this.state.currentImage[0]);
        }
    }
    //Showing the previuos image when the left arrow is clicked
    showPreviuosImage(collection, element) {
        const max = collection.images.length - 1;
        if (this.state.currentImage[collection.id] <= 0) {
            this.state.currentImage[collection.id] = max;
        } else {
            this.state.currentImage[collection.id]--;
        }
        this.setState({ update: this.state.update++ });
        this.updateShownImage(collection, element);
        if (typeof this.state.onIndexChanged !== 'undefined') {
            this.state.onIndexChanged(this.state.currentImage[0]);
        }
    }
    //Updating the search query
    componentWillReceiveProps(nextProp) {
        if (nextProp.images) {
            this.setState({ images: nextProp.images }, () => {
                this.getAllImages();
            });
        }
        if (nextProp.search) {
            console.log(nextProp.search);
            this.setState({ search: nextProp.search }, () => {
                this.getAllImages();
            });
        } else if (nextProp.search === '' && this.state.search !== '') {
            this.setState({ search: '' }, () => {
                this.getAllImages();
            });
        }
        if (nextProp.query) {
            if (Object.keys(nextProp.query).length > 0) {
                console.log(nextProp.query);
                this.setState({ query: nextProp.query }, () => {
                    this.getAllImages();
                });
            }
        }
    }
    //Search images
    search(searchQuery) {
        console.log('searching')
        //get the images from the Database
        const db = new Database();

        // if (this.state.countSearch)
        db.calculateSearch(searchQuery);
        const search = searchQuery.toString().replace('"', '').replace('״', '').split(',').join('');
        // let newLimit = 0;
        // if (this.state.limit == 'screen') {
        //     if (window.innerWidth >= 740)
        //         newLimit = Math.floor(window.innerWidth / 270);
        //     else
        //         newLimit = 4;
        // }
        // if (newLimit <= 3) {
        //     newLimit = newLimit * 2;
        // }
        // this.pNewLimit = newLimit;
        console.log('starting search')
        db.search(search).then(images => {
            console.log('results came')
            //Images recived as array
            if (images.length === 0) {
                console.log('no')
                const loadingText = (<h4>
                    לא נמצאו תוצאות תואמות<br />
                    נסו לכתוב בשורת החיפוש בכתיב מלא/חסר או ניסוח אחר שיכול להתאים לבקשתך
                </h4>)
                this.setState({loadingText}, () => {
                    console.log(this.state.loadingText)
                })
            }
            images.forEach((image, index) => {
                if (!this.state.showHidden) {
                    if (!image.visible) {
                        return
                    }
                }
                //Get the image url
                const files = new Files();
                if (this.state.notFromCollection != '') {
                    if (image.collectionId != this.state.notFromCollection) {
                        const fullImage = image.image.url;
                        const thumbnail = image.thumbnail.url;
                        const cover = image.cover.url;

                        //hide the searching text
                        this.setState({ searching: false });

                        //Add the images to the collections array
                        this.addImageToArray(image.collectionId, {
                            thumbnail, 
                            title: image.title, 
                            id: image.id, 
                            count: 1, 
                            fullImage, 
                            cover, 
                            visible: image.visible,
                            rotate: image.rotate,
                            who: image.who, 
                            corps: image.corps,
                            keywords: image.keywords,
                        });
                        this.setState({ update: this.state.update++ });

                    } else {
                        this.setState({ searching: false });
                    }
                } else {
                    const fullImage = image.image.url;
                    const thumbnail = image.thumbnail.url;
                    const cover = image.cover.url;

                    //hide the searching text
                    this.setState({ searching: false });

                    //Add the images to the collections array
                        this.addImageToArray(image.collectionId, {
                            thumbnail, 
                            title: image.title, 
                            id: image.id, 
                            count: 1, 
                            fullImage, 
                            cover, 
                            visible: image.visible,
                            rotate: image.rotate,
                            who: image.who, 
                            corps: image.corps,
                            keywords: image.keywords,
                        });
                    this.setState({ update: this.state.update++ });

                }
                if (images.length === index + 1) {
                    console.log(this.state.collections)
                }
            })
        }).catch(() => {
            //hide the searching text
            this.setState({ searching: false });

        })
    }
    //Showing the image indexed like the this.state.currentImage[collection.id] value;
    updateShownImage(collection, element) {
        element.parentNode.parentNode.querySelectorAll('.image-item').forEach((element, index) => {
            if (index === this.state.currentImage[collection.id]) {
                element.style.opacity = 1;
                element.style.zIndex = 2;
            } else {
                element.style.opacity = 0;
                element.style.zIndex = 1;
            }
        })
    }
    loadImage(element) {
        element.classList.remove('zero-opacity')
        element.parentNode.querySelector('.image-gradient').classList.remove('zero-opacity')
    }
    showPreview(image, download, title, id, rotate) {
        if (this.props.onImageClick === 'merge') {
            console.log(id);
            this.props.mergeCollection(id);
        } else if (this.state.hidePreview) {
            new Redirect(`${this.props.onImageClick}/${id}`)
        } else {
            this.setState({
                previewImage: image,
                previewTitle: title,
                previewImageId: id,
                previewRotation: rotate,
                fullImage: download,
                preview: true
            }, () => {

                setTimeout(() => {
                    console.log(this.props.itemIndex);
                    document.querySelectorAll('.preview')[this.props.itemIndex].style.opacity = '1';
                }, 1)
            })
        }
    }
    hidePreview(i) {
        console.log(i)
        document.querySelectorAll('.preview')[i].style.opacity = '0';
        setTimeout(() => {
            this.setState({
                preview: false,
                previewImage: null,
                previewTitle: '',
                previewImageId: '',
                previewRotation: '',
            });
        }, 400);
    }
    copyIframe(imagelink, title) {
        let str = ``;
        if (typeof title !== 'undefined')
            str = `<div style="width: 100%; direction: rtl;"><img src="${imagelink}" width="100%" alt="${title}"><p><strong>"${title}"</strong> מתוך <a href="https://archive.idf-spokesperson.com">המאגר</a></p></div>`;
        else
            str = `<div style="width: 100%; direction: rtl;"><img src="${imagelink}" width="100%"><p>מתוך <a href="https://archive.idf-spokesperson.com">המאגר</a></p></div>`;
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        new Alert(
            'ה-HTML הועתק',
            'להטמעת התמונה באתרכם, הדביקו את קוד ה-HTML בדף הרצוי'
        );
    }
    toggleHoverStatus(e) {
        const status = e.target.parentNode.querySelector('.status-text');
        if (status.classList.contains('hide')) {
            status.classList.remove('hide');
        } else {
            status.classList.add('hide');
        }
    }

    render() {
        const sliderSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 7,
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 2550,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 7,
                  infinite: true,
                }
              },
              {
                breakpoint: 2250,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 6,
                  infinite: true,
                }
              },
              {
                breakpoint: 1950,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 5,
                  infinite: true,
                }
              },
              {
                breakpoint: 1650,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  infinite: true,
                }
              },
              {
                breakpoint: 1350,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                }
              },
              {
                breakpoint: 970,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 670,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        return (<React.Fragment>
            <div className={`preview ${this.state.preview ? '' : 'hide'}`} >
                <div className="black-screen" onClick={() => { this.hidePreview(this.props.itemIndex) }}></div>

                <div className="preview-wrapper">
                     <div className={this.state.previewRotation && this.state.previewRotation !== 'false' ? (this.state.previewRotation === 'right' ? 'rotate-right' : 'rotate-left' ) : '' }>
                        <img src={this.state.previewImage} alt={this.state.previewTitle} />
                    </div>
                    <div className="title">
                        {this.state.previewTitle}
                    </div>
                    <div className='preview-buttons'>
                        <a href={this.state.fullImage} className="image-options download" download>
                            <button>הורדת התמונה</button>
                        </a>
                        <a className="image-options" onClick={() => this.copyIframe(this.state.previewImage, this.state.previewTitle)}>
                            <button >HTML להטמעה באתר</button>
                        </a>
                    </div>
                    <a className="image-options" onClick={() => { window.location.replace(`${this.props.onImageClick}/${this.state.previewImageId}`) }}>
                        פרטים נוספים
                    </a>
                </div>
                <div className="exit" onClick={() => { this.hidePreview(this.props.itemIndex) }}>
                    <img src="/assets/icons/exit.svg" />
                </div>

            </div>
            {this.props.home ? <div className={`image-list ${(this.state.small) ? 'small' : ''} ${(this.state.big) ? 'big' : ''}`}>
                <div className={`searching ${this.state.searching ? '' : 'hide'}`}>
                    <div className="text">{this.state.loadingText}</div>
                </div>
                <Slider {...sliderSettings}>
                {this.state.collections.map((collection, index) => {
                    if (typeof collection.id !== 'undefined') {
                        let counter = 0;
                        return (
                            <div className={`image-group${(this.state.small) ? ' small' : ''}${(this.state.big) ? ' big' : ''}`} id={`group-${collection.id}`} key={index}>
                                <div className={`image-buttons${collection.images.length <= 1 ? ' hide' : ''}`}>
                                    <button className="image-buttons-right" onClick={e => this.showNextImage(collection, e.target)}>{`<`}</button>
                                    <button className="image-buttons-left" onClick={e => this.showPreviuosImage(collection, e.target)}>{'>'}</button>
                                </div>
                                <div className={`image-counter${collection.images.length <= 1 ? ' hide' : ''}`}>
                                    {this.state.currentImage[collection.id] + 1}<span className="slash"></span>{collection.images.length}
                                </div>
                                {(this.state.showInfo) ?
                                    <div className="more-info">
                                        <div className={(collection.images[0].visible) ? 'green-status' : 'red-status'} onMouseEnter={(e) => this.toggleHoverStatus(e)} onMouseLeave={(e) => this.toggleHoverStatus(e)}></div>
                                        <div className="status-text hide">
                                            {(collection.images[0].visible) ? 'פומבי' : 'מוסתר'}
                                        </div>
                                    </div>
                                    :
                                    ''}
                                {
                                    collection.images.map((src, i) => {
                                        counter++;
                                        let zIndex = 2;
                                        let opacity = 1;
                                        if (counter !== 1) {
                                            zIndex = 1;
                                            opacity = 0;
                                        }

                                        return (
                                            //     <div key={i} onClick={() => { new Redirect(`${this.props.onImageClick}/${src.id}`) }} className={`image-item${(this.state.small) ? ' small' : ''}${(this.state.big) ? ' big' : ''}`} id={`#image-${src.id}`} style={{ zIndex, opacity }} >
                                            //         <img onLoad={e => { this.loadImage(e.target) }} src={src.link} alt={src.title} className="image-in-list zero-opacity" />
                                            //         <div className={`image-title${(this.state.title) ? '' : ' hide'}`}>{src.title}</div>
                                            //         <div className="image-gradient zero-opacity"></div>
                                            //     </div>
                                            <div key={i} onClick={() => { this.showPreview(src.cover, src.fullImage, src.title, src.id, src.rotate)}} className={`image-item ${(this.state.small) ? 'small' : ''}${(this.state.big) ? 'big' : ''} ${src} ${src.rotate && src.rotate !== 'false' ? (src.rotate === 'right' ? 'rotate-right' : 'rotate-left' ) : '' }`} id={`#image-${src.id}`} style={{ zIndex, opacity }} >
                                                <img onLoad={e => { this.loadImage(e.target) }} src={src.thumbnail} alt={src.title} className={`image-in-list zero-opacity`}/>
                                                <div className={`image-title${(this.state.title) ? '' : ' hide'}`}>{src.title}</div>
                                                <div className="image-gradient zero-opacity"></div>
                                            </div>
                                        );

                                    })
                                }
                            </div>

                        )
                    }

                })}
                </Slider>
            </div> : <div className={`image-list ${(this.state.small) ? 'small' : ''} ${(this.state.big) ? 'big' : ''}`}>
                <div className={`searching ${this.state.searching ? '' : 'hide'}`}>
                    <div className="text">{this.state.loadingText}</div>
                </div>
                {this.state.collections.map((collection, index) => {
                    if (typeof collection.id !== 'undefined') {
                        let counter = 0;
                        return (
                            <div className={`image-group${(this.state.small) ? ' small' : ''}${(this.state.big) ? ' big' : ''}`} id={`group-${collection.id}`} key={index}>
                                <div className={`image-buttons${collection.images.length <= 1 ? ' hide' : ''}`}>
                                    <button className="image-buttons-right" onClick={e => this.showNextImage(collection, e.target)}>{`<`}</button>
                                    <button className="image-buttons-left" onClick={e => this.showPreviuosImage(collection, e.target)}>{'>'}</button>
                                </div>
                                <div className={`image-counter${collection.images.length <= 1 ? ' hide' : ''}`}>
                                    {this.state.currentImage[collection.id] + 1}<span className="slash"></span>{collection.images.length}
                                </div>
                                {(this.state.showInfo) ?
                                    <div className="more-info">
                                        <div className={(collection.images[0].visible) ? 'green-status' : 'red-status'} onMouseEnter={(e) => this.toggleHoverStatus(e)} onMouseLeave={(e) => this.toggleHoverStatus(e)}></div>
                                        <div className="status-text hide">
                                            {(collection.images[0].visible) ? 'פומבי' : 'מוסתר'}
                                        </div>
                                    </div>
                                    :
                                    ''}
                                {
                                    collection.images.map((src, i) => {
                                        counter++;
                                        let zIndex = 2;
                                        let opacity = 1;
                                        if (counter !== 1) {
                                            zIndex = 1;
                                            opacity = 0;
                                        }

                                        return (
                                            //     <div key={i} onClick={() => { new Redirect(`${this.props.onImageClick}/${src.id}`) }} className={`image-item${(this.state.small) ? ' small' : ''}${(this.state.big) ? ' big' : ''}`} id={`#image-${src.id}`} style={{ zIndex, opacity }} >
                                            //         <img onLoad={e => { this.loadImage(e.target) }} src={src.link} alt={src.title} className="image-in-list zero-opacity" />
                                            //         <div className={`image-title${(this.state.title) ? '' : ' hide'}`}>{src.title}</div>
                                            //         <div className="image-gradient zero-opacity"></div>
                                            //     </div>
                                            <div key={i} onClick={() => { this.showPreview(src.cover, src.fullImage, src.title, this.props.show ? src.id : collection.id, src.rotate) }} className={`image-item${(this.state.small) ? ' small' : ''}${(this.state.big) ? ' big' : ''} ${src.rotate && src.rotate !== 'false' ? (src.rotate === 'right' ? 'rotate-right' : 'rotate-left' ) : '' }`} id={`#image-${src.id} `} style={{ zIndex, opacity }} >
                                                <img onLoad={e => { this.loadImage(e.target) }} src={src.thumbnail} alt={src.title} className="image-in-list zero-opacity" />
                                                <div className={`image-title${(this.state.title) ? '' : ' hide'}`}>{src.title}</div>
                                                <div className="image-gradient zero-opacity"></div>
                                            </div>
                                        );

                                    })
                                }
                            </div>

                        )
                    }

                })}
            </div> }
        </React.Fragment>);
    }
}

export default ImageList;