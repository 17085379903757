import React, { Component } from "react";
import Auth from "../../src/auth";
import LoadingScreen from "../../src/loading";
import "../../css/main.scss";
import Helmet from "react-helmet";
import Header from "../../components/header";
import DashboardButton from "../../components/dashboardbutton";
import AccountCircle from "../../components/accountCircle";
import ImageList from "../../components/imageList";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Reorder, {
  reorder,
  reorderImmutable,
  reorderFromTo,
  reorderFromToImmutable,
} from "react-reorder";
import Database from "../../src/database";
import Redirect from "../../src/redirect";
import Alert from "../../src/alert";
import Confirm from "../../src/confirm";
import {Collapse} from '@material-ui/core';
import Terms from '../../src/terms';

class Dashbord extends Component {
    user;
    loops = 0;
    usersUpdates = [];
    state = {
        name: "",
        email: "",
        search: "",
        users: [],
        query: {},
        queryIndex: 0,
        list: [],
        usersUpdates: [],
        isCommander: false,
        update: 0,
        feedback: [],
        imageCount: 0,
        publicImageCount: 0,
        collectionCount: 0,
    };

    constructor(props) {
        super(props);

        this.search = this.search.bind(this);
        this.getCurrentList = this.getCurrentList.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.getValueById = this.getValueById.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.reduceArrayById = this.reduceArrayById.bind(this);
    }

    async getUserName() {
        //Calling the auth class which has a user function in it
        const auth = new Auth();

        //Getting the user data
        this.user = await auth.user();
        this.setState({
            name: this.user.name,
            email: this.user.email
        });
    }

    async componentDidMount() {
        //Show the loading screen
        const loadingScreen = new LoadingScreen();
        loadingScreen.startLoading();

        //Checking if the user is logged in
        const auth = new Auth();
        const permisstion = await auth.hasPermission("admin");
        if (!permisstion) {
            new Redirect("login", "replace");
        }

        let commanderCheck = await auth.hasPermission("commander");

        this.setState({
            isCommander: commanderCheck
        });

        if (commanderCheck) {
            const db = new Database();
            db.get("users", {}).then((users) => {
                users.forEach(user => {
                    user.collapse = false;
                })
                users.sort((a, b) => {
                    if (a.permissions.includes('commander') && !b.permissions.includes('commander')) {
                        return -1;
                    } else if (a.permissions.includes('admin') && !b.permissions.includes('admin')) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                this.setState({users});
            });
        }

        //If the user is logged in, getting its data
        this.getUserName();

        //Load the home layout list
        this.getCurrentList();

        //Hide the loading screen
        loadingScreen.stopLoading();

        this.getFeedback();
    }

    search(e) {
        e.preventDefault();
        this.setState({
            search: e.target.querySelector("#search").value
        });
    }

    onReorder(event, previousIndex, nextIndex, fromId, toId) {
        //changing the order of the home page layout
        this.setState({
                list: reorder(this.state.list, previousIndex, nextIndex),
            },
            () => {
                //updating the database with the new order
                const db = new Database();
                db.update(
                    "settings", {
                        item: "home-layout"
                    }, {
                        item: "home-layout",
                        body: this.state.list
                    }
                );
            }
        );
    }

    getCurrentList() {
        //get current home page layout
        const db = new Database();
        db.get("settings", {
            item: "home-layout"
        }).then((homeList) => {
            this.setState({
                list: homeList[0].body
            });
        });
    }

    addItemToList(formObject) {
        //Adding a new home page item
        this.state.list.push({
            id: new Date().getTime(),
            title: formObject.title,
            key: formObject.keywords,
        });
        this.setState({
            update: this.state.update++
        });
        const db = new Database();
        db.update(
            "settings", {
                item: "home-layout"
            }, {
                item: "home-layout",
                body: this.state.list
            }
        );
    }

    submitForm(form) {
        form.preventDefault();
        //Getting the form data
        const formElement = form.target;
        const formData = new FormData(formElement);
        const formObject = this.convertFormDataToObject(formData);

        //Escaping HTML values
        this.makeObjectSafe(formObject);

        this.addItemToList(formObject);
        form.target.reset();
    }

    convertFormDataToObject = (formData) => {
        //Converting the form data into JavaScript object.
        let jsonObject = {};
        for (const [key, value] of formData.entries()) {
            //ignoring the inputs with the type of file, so we have more control of how files are added to this object
            if (!key.includes("file")) {
                jsonObject[key] = value;
            }
        }
        return jsonObject;
    };

    makeObjectSafe(unsafeObject) {
        Object.keys(unsafeObject).forEach((key) => {
            unsafeObject[key] = this.escapeHtml(unsafeObject[key]);
        });
    }

    escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    deleteItem(itemId) {
        //deleting an home page layout item
        this.state.list = this.state.list.filter(
            (item, index, self) => item.id !== itemId
        );
        this.setState({
            update: this.state.update++
        }, () => {
            const db = new Database();
            db.update(
                "settings", {
                    item: "home-layout"
                }, {
                    item: "home-layout",
                    body: this.state.list
                }
            );
        });
    }

    getBiggestPermission(permissions) {
        //getting the name of the permission recevied
        if (permissions.includes("commander")) {
            return "מפקד";
        } else if (permissions.includes("admin")) {
            return "עורך תוכן";
        } else {
            return "אורח";
        }
    }

    async removeUser(email) {
        //Removing
        const confirmResult = await new Confirm(
            "בטוח?",
            "לא ניתן לשחזר את המשתמש לאחר מחיקה."
        ).show();
        if (confirmResult) {
            const db = new Database();
            db.delete("users", {
                email
            }).then((res) => {
                const userIndex = this.state.users.findIndex(user => user.email === email);
                let users = [...this.state.users];
                users.splice(userIndex, 1);
                this.setState({
                    users
                });
                new Alert("משתמש נמחק בהצלחה", '');
            }).catch((err) => {
                new Alert("אופס...", err)
            });
        }
    }

    saveUser(email) {
        this.loops++;
        let saveObj = {};
        let saved = false;
        for (let obj of this.state.usersUpdates) {
            if (!saved) {
                if (obj.email === email) {
                    saveObj = {
                        ...obj
                    };
                    saved = true;
                }
            }
        }

        delete saveObj._id;
        delete saveObj.email;

        const userIndex = this.state.users.findIndex(user => user.email === email);
        let users = [...this.state.users];
        users[userIndex] = this.state.usersUpdates[userIndex];
        users.sort((a, b) => {
            if (a.permissions.includes('commander') && !b.permissions.includes('commander')) {
                return -1;
            } else if (a.permissions.includes('admin') && !b.permissions.includes('admin')) {
                return -1;
            } else {
                return 0;
            }
        })
        this.setState({
            users
        });

        const db = new Database();

        // console.log(this.state.usersUpdates, this.loops);
        // console.log(saveObj, this.loops);
        db.forceUpdate("users", {
                email
            }, saveObj)
            .then(
                new Alert(
                    "נשמר",
                    "כדי שהשינויים ישפיעו, המשתמש צריך להתנתק ולהתחבר מחדש."
                )
            )
            .catch((err) => new Alert("אופס...", err));
    }

    toggleUserForm(e, index) {
        let users = this.state.users;
        users[index].collapse = !users[index].collapse;
        this.setState({
            users
        })
        // if (e.target.classList.contains("save")) {
        //     return;
        // } else if (e.target.classList.contains("user-info")) {
        //     let formClasses = e.target.querySelector(".edit-user").classList;
        //     if (formClasses.contains("hide")) {
        //         formClasses.remove("hide");
        //     } else {
        //         formClasses.add("hide");
        //     }
        // } else if (e.target.classList.contains("element")) {
        //     let formClasses = e.target.parentNode.querySelector(".edit-user")
        //     .classList;
        //     if (formClasses.contains("hide")) {
        //         formClasses.remove("hide");
        //     } else {
        //         formClasses.add("hide");
        //     }
        // }
    }

    toggleVisibilitySearch(e) {
        const value = JSON.parse(e.target.value);
        const id = parseInt(e.target.getAttribute("id").split("opt").join("")) - 1;
        console.log(value);
        this.setState({
            query: value
        });
        this.setState({
            queryIndex: id
        });
    }

    getValueById(key, id) {
        for (let obj of this.state.usersUpdates) {
            if (obj._id === id) {
                return obj[key];
            }
        }
        return "";
    }
    setValueById(key, id, value) {
        let i = 0;
        let tempUsers = this.state.usersUpdates.map((obj) => {
            let newObj = {
                ...obj
            };
            if (newObj._id === id) {
                newObj[key] = value;
            }
            return newObj;
        });
        this.setState({
            usersUpdates: tempUsers
        }, () => {
            this.reduceArrayById(this.state.usersUpdates);
        });
        i++;
    }

    reduceArrayById(array) {
        // console.log("hepa");
        // console.log(array, "unfiltered");
        let tempArray = [...array];
        let filtered = tempArray.filter((item, index, self) => {
            //   console.log(
            //     index,
            //     self.findIndex((fi, i) => fi._id == item._id),
            //     index === self.findIndex((fi, i) => fi._id == item._id)
            //   );
            return index === self.findIndex((fi, i) => fi._id == item._id);
        });

        this.setState({
            usersUpdates: filtered
        }, () => {
            //   console.log(this.state.usersUpdates, "filtered");
        });
    }

    togglePermission(permission, id) {
        for (let obj of this.state.usersUpdates) {
            if (obj._id === id) {
                if (obj.permissions.split(",").includes(permission)) {
                    obj.permissions = obj.permissions
                        .split(",")
                        .filter((nObj) => {
                            return nObj !== permission;
                        })
                        .join(",");
                    console.log("removed", id);
                } else {
                    let tempPermissions = obj.permissions.split(",");
                    tempPermissions.push(permission);
                    obj.permissions = tempPermissions.join(",");
                    console.log("added", id);
                }
            }
        }
        this.setState({
            update: 0
        });
    }
    getFeedback = () => {
        const db = new Database();

        db.get('Feedback').then(res => {
            if (res.length === 0) {
                this.setState({
                    feedback: 'no-feedback'
                })
            } else {
                this.setState({
                    feedback: res
                });
            }
        })
    }
    deleteFeedback = (number) => {
        let feedback = [...this.state.feedback];
        const id = feedback[number].id
        feedback.splice(number, 1);
        this.setState({
            feedback
        })
        const db = new Database();
        db.delete('Feedback', {
            id
        });
    }
    updateCollectionCount = (collectionCount) => (
        this.setState({collectionCount})
    )
    updateImageCount = (imageCount) => (
        this.setState({imageCount})
    )
    updatePublicImageCount = (publicImageCount) => {
        this.setState({publicImageCount});
    }

    render() {
        return (
            <div className='dashboard'>
                <React.Fragment>
                <Helmet>
                <title>המאגר | לוח ניהול</title>
                </Helmet>
                <Header title="לוח ניהול" link={false} />
                <AccountCircle
                user={{ name: this.state.name, email: this.state.email }}
                />
                <div className='count'>
                    <h3>אוספים במאגר: {this.state.collectionCount}</h3>
                    <h3>תמונות במאגר: {this.state.imageCount}</h3>
                    <h3>תמונות פומביות: {this.state.publicImageCount}</h3>
                </div>

                <Tabs>
                <TabList>
                    <Tab>תמונות</Tab>
                    <span className="separator"></span>
                    <Tab>פריסת דף הבית</Tab>
                    <span className="separator"></span>
                    <Tab>פידבק</Tab>
                    {this.state.isCommander ? (
                    <React.Fragment>
                        <span className="separator"></span>
                        <Tab>משתמשים</Tab>
                    </React.Fragment>
                    ) : (
                    ""
                    )}
                    </TabList>

                    <TabPanel>
                    <form onSubmit={(e) => this.search(e)}>
                    <input
                        className="search"
                        type="text"
                        name="search"
                        id="search"
                        placeholder="לחיפוש, הזינו טקסט ולחצו אנטר"
                    ></input>
                    <div className="search-options">
                        <input
                        type="radio"
                        name="options"
                        id="opt1"
                        value="{}"
                        onChange={(e) => this.toggleVisibilitySearch(e)}
                        checked={this.state.queryIndex === 0 ? "checked" : ""}
                        />
                        <label htmlFor="opt1">הצג הכל</label>
                        <input
                        type="radio"
                        name="options"
                        id="opt2"
                        value='{"visible":true}'
                        onChange={(e) => this.toggleVisibilitySearch(e)}
                        checked={this.state.queryIndex === 1 ? "checked" : ""}
                        />
                        <label htmlFor="opt2">ציבורי בלבד</label>
                        <input
                        type="radio"
                        name="options"
                        id="opt3"
                        value='{"visible":false}'
                        onChange={(e) => this.toggleVisibilitySearch(e)}
                        checked={this.state.queryIndex === 2 ? "checked" : ""}
                        />
                        <label htmlFor="opt3">מוסתר בלבד</label>
                    </div>
                    </form>
                    <ImageList
                        search={this.state.search}
                        onImageClick="edit"
                        hidePreview={true}
                        showHidden={true}
                        showInfo={true}
                        query={this.state.query}
                        updateCollectionCount={(collectionCount) => this.updateCollectionCount(collectionCount)}
                        updateImageCount={(ImageCount) => this.updateImageCount(ImageCount)}
                        updatePublicImageCount={(publicImageCount) => this.updatePublicImageCount(publicImageCount)}
                        manage={true}
                    ></ImageList>
                    <button
                    className="add-images"
                    onClick={() => {
                        new Redirect("upload");
                    }}
                    >
                    +
                    </button>
                </TabPanel>

                    <TabPanel>
                    <div className="reorder-area">
                    <div className="left-side">
                        <div className="section-title">סידור דף הבית</div>
                        <Reorder
                        reorderId="my-list" // Unique ID that is used internally to track this list (required)
                        component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                        placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                        draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                        lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                        holdTime={100} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                        touchHoldTime={100} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                        mouseHoldTime={100} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                        onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
                        autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                        disabled={false} // Disable reordering (optional), defaults to false
                        disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                        placeholder={
                            <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
                        }
                        >
                        {
                            this.state.list.map((item, i) => (
                            <div className="home-element" key={i}>
                                <div className="scroll-icon"></div>
                                <div
                                className="trash-icon"
                                onClick={() => this.deleteItem(item.id)}
                                >
                                X
                                </div>
                                <div className="title">{item.title}</div>
                                <div className="key">{item.key}</div>
                            </div>
                            ))
                            /*
                                                Note this example is an ImmutableJS List so we must convert it to an array.
                                                I've left this up to you to covert to an array, as react-reorder updates a lot,
                                                and if I called this internally it could get rather slow,
                                                whereas you have greater control over your component updates.
                                                */
                        }
                        </Reorder>
                    </div>

                    <div className="right-side">
                        <form
                        className="upload-form"
                        onSubmit={(e) => this.submitForm(e)}
                        >
                        <div className="section-title">הוספת גלריה לדף הבית</div>
                        <label htmlFor="title">כותרת</label>
                        <input type="text" id="title" name="title"></input>
                        <label htmlFor="keywords">מילות מפתח</label>
                        <input
                            type="text"
                            id="keywords"
                            name="keywords"
                            placeholder="מופרדות בפסיקים. לדוגמה: רמטכ״ל, חנוכה, צנחנים"
                        ></input>
                        <br />
                        <button
                            className="save"
                            style={{ left: 0, width: "100%", margin: "15px 0" }}
                        >
                            הוספה
                        </button>
                        </form>
                    </div>
                    </div>
                </TabPanel>
                
                    <TabPanel>
                        <div className='feedback-area'>
                            <div className='feedback-loading'>
                                <h2 className='text' style={{display: `${this.state.feedback === [] ? 'block' : this.state.feedback === 'no-feedback' ? 'block' : 'none'}`}}>{this.state.feedback === 'no-feedback' ? 'לא נמצאו פידבקים' : 'טוען...'}</h2>
                            </div>
                            {this.state.feedback === 'no-feedback' ? '' : 
                            this.state.feedback.map((item, index) => (
                                <div className='feedback-item'>
                                    <div className="user-info">
                                        <div className="feedback-name">{item.name}</div>
                                        <div className="feedback-text">{item.query}</div>
                                        <div className='delete-container'>
                                            <button className='delete' onClick={() => this.deleteFeedback(index)}>מחיקה</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                            }       
                        </div>
                    </TabPanel>

                {this.state.isCommander ? (
                    <TabPanel>
                        <div className='users-area'>
                    {this.state.users.map((user, index) => {
                        this.state.usersUpdates.push({
                        _id: user._id,
                        name: user.name,
                        email: user.email,
                        phone: user.phone,
                        permissions: user.permissions,
                        });
                        return (
                            <div>
                            <div
                                className="user-info"
                                key={index}
                                onClick={(e) => this.toggleUserForm(e, index)}
                            >
                                <div className="element user-name">{user.name}</div>
                                <div className="element">
                                {this.getBiggestPermission(user.permissions.split(","))}
                                </div>
                                <div className="element">{user.email}</div>
                                <div className="element">{user.phone}</div>
                                
                            </div>
                            <Collapse classes={{container: 'edit-user'}} in={this.state.users[index].collapse}>
                                <label htmlFor="name">שם מלא</label>
                                    <input
                                    type="text"
                                    id="name"
                                    value={this.getValueById("name", user._id)}
                                    onChange={(e) =>
                                        this.setValueById("name", user._id, e.target.value)
                                    }
                                    ></input>
                                    <label htmlFor="email">כתובת אימייל</label>
                                    <input
                                    type="email"
                                    id="email"
                                    value={this.getValueById("email", user._id)}
                                    onChange={(e) =>
                                        this.setValueById("email", user._id, e.target.value)
                                    }
                                    readOnly
                                    ></input>
                                    <label htmlFor="phone">מספר פלאפון</label>
                                    <input
                                    type="tel"
                                    id="phone"
                                    value={this.getValueById("phone", user._id)}
                                    onChange={(e) =>
                                        this.setValueById("phone", user._id, e.target.value)
                                    }
                                    ></input>
                                    <label>הרשאות:</label>
                                    <input
                                    type="checkbox"
                                    name="admin"
                                    value="admin"
                                    id={`admin${index}`}
                                    checked={
                                        this.getValueById("permissions", user._id)
                                        .split(",")
                                        .includes("admin")
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={() =>
                                        this.togglePermission("admin", user._id)
                                    }
                                    />
                                    <label for={`admin${index}`} className="radio-label">
                                    גישה ללוח הניהול
                                    </label>
                                    <br />
                                    <input
                                    type="checkbox"
                                    name="commander"
                                    value="commander"
                                    id={`commander${index}`}
                                    checked={
                                        this.getValueById("permissions", user._id)
                                        .split(",")
                                        .includes("commander")
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={() =>
                                        this.togglePermission("commander", user._id)
                                    }
                                    />
                                    <label for={`commander${index}`} className="radio-label">
                                    הרשאות מפקד
                                    </label>
                                    <button
                                    className="delete"
                                    onClick={(e) => this.removeUser(user.email)}
                                    >
                                    מחיקת משתמש
                                    </button>
                                    <button
                                    className="save"
                                    onClick={(e) => this.saveUser(user.email)}
                                    >
                                    שמירה
                                    </button>
                                </Collapse>
                        </div>
                            
                        );
                    })}
                    </div>
                    </TabPanel>
                ) : (
                    ""
                )}
                </Tabs>
                {/* <div className="dashboard-area">
                            <DashboardButton link="upload" icon="upload" title="העלאת תמונה" />
                        </div> */}
            </React.Fragment>
        </div>
    );
  }
}

export default Dashbord;
