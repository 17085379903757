import React, { Component } from 'react';
import Auth from '../src/auth';

class AccountCircle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showmenu: false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.logout = this.logout.bind(this);

    }

    logout() {
        this.setState({ showmenu: false });
        const auth = new Auth();
        auth.logOut();
    }

    toggleMenu() {
        this.setState({ showmenu: !this.state.showmenu });
    }

    render() {
        return (<React.Fragment>
            <div className="account-circle" onClick={this.toggleMenu}>
                <div className="circle">

                    {this.props.user.name[0]}
                </div>
                <div className="name">
                    {this.props.user.name}
                </div>
            </div>
            <div className={`account-menu ${this.state.showmenu ? 'active' : ''}`}>
                <div className="menu-item red" onClick={this.logout}>התנתק</div>

            </div>
        </React.Fragment>);
    }
}

export default AccountCircle;