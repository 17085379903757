import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "../../css/main.scss";
import Header from "../../components/header";
import { Helmet } from "react-helmet";
import Auth from "../../src/auth";
import LoadingScreen from "../../src/loading";
import Alert from "../../src/alert";
import Redirect from "../../src/redirect";

class Register extends Component {
  randomImage = 'https://picsum.photos/2000/500.jpg';
  state = {
    name: "",
    password: "",
    verfypassword: "",
    email: "",
    phone: ""
  };

  //Handeling the click on the register button
  handleClick(event) {
    event.preventDefault();
    new Alert("טוען...", ' ', true);

    //Calling the auth class, wich have register function in it
    const auth = new Auth();

    //Registering the user with the data provided
    auth.register({
        name: this.state.name,
        password: this.state.password,
        email: this.state.email,
        phone: this.state.phone
    }).then(status => {
        //If everithing is okay, redirecting to the dashboard
        if (status.status === 200) {
            const alert = document.querySelector('#alert');
            alert.parentNode.removeChild(alert);
            new Alert('נרשמת בהצלחה', 'יש לפנות אל מנהלי המערכת על מנת לקבל הרשאות', false, '; window.location.replace(`/manage`, `_self`)')
        } else {
            //If somthing's wrong, showing the status message in alert 
            new Alert('אופס...', status.message);
        }
    });
  }

  async componentDidMount() {
    //Show the loading screen
    const loadingScreen = new LoadingScreen();
    loadingScreen.startLoading();

    //Checking if the user is already logged in    
    const auth = new Auth();
    const permisstion = await auth.hasPermission('admin');
    if (permisstion) {
      new Redirect('./', 'replace');
    }
    else {
      loadingScreen.stopLoading();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            הרשמה
          </title>
        </Helmet>
        <Header title="הרשמה" link={false} />
        <div className="login">
          <form onSubmit={e => this.handleClick(e)}>
            <label for="name">שם מלא</label>
            <input type="text" id="name" name="name"
              onInput={e => this.setState({ name: e.target.value })} required></input>
            <label for="phone">מספר פלאפון</label>
            <input type="tel" id="phone" name="phone" onInput={e => this.setState({ phone: e.target.value })}
              required></input>
            <label for="email">כתובת אימייל</label>
            <input type="email" id="email" name="email" onInput={e => this.setState({ email: e.target.value })} required></input>
            <label for="password">סיסמה</label>
            <input type="password" id="password" name="password" onInput={e => this.setState({ password: e.target.value })} autocomplete="new-password" required></input>
            <label for="vpassword">אימות סיסמה</label>
            <input type="password" id="vpassword" name="vpassword" onInput={e => this.setState({ verfypassword: e.target.value })} autocomplete="new-password" required></input>
            <button>הרשמה</button>
            <Link to="login">יש לכם משתמש? התחברות</Link>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Register;
