import React, { Component } from 'react';
import ContentPage from '../components/contentPage';

class Contact extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <ContentPage title="יצירת קשר" noLogo={true}>
                    <p>לכל פניה, בקשה, או הצעה, אנו זמינים עבורכם במספר הבא:</p>
                    <h2>מספר פניות הציבור</h2>
                    <p>052-9209910</p>
                    <a href='https://cutt.ly/kjQC1JD'>
                        <img className='whatsapp' style={{width: '50px'}} src='assets/icons/whatsapp.png'></img>
                    </a>
                </ContentPage>
            </React.Fragment>
        );
    }
}

export default Contact;