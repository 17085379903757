class Redirect {
    constructor(url, method = 'href') {
        if (url === '#') {
            window.location.reload();
            return;
        }
        let current = window.location.href.split('?')[0].replace('#/', '').replace('#', '');
        let last = window.location.href.split('/')[window.location.href.split('/').length - 1];
        if (current.endsWith('/')) {
            let goto = url;
            if (method === 'replace') {
                window.location.replace(goto)
            }
            else {
                window.location.href = goto;
            }
        }
        else {
            let goto = `${last}/${url}`;
            if (method === 'replace') {
                window.location.replace(goto)
            }
            else {
                window.location.href = goto;
            }
        }

    }
}

export default Redirect;