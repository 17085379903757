import React, { Component } from "react";
import "../css/main.scss";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import Header from "../components/header";
import Database from "../src/database";
import LoadingScreen from "../src/loading";
import ImageList from "../components/imageList";
import Footer from '../components/footer';
import Terms from '../src/terms';


class Home extends Component {
    state = {
        homeLayout: [],
        terms: false,
    };
    checkForTermsAgreement = () => {
        if (window.localStorage.termsAgreed === 'true') {
            const agreementTime = parseInt(window.localStorage.agreementTime);
            const now = new Date().getTime();
            if ((agreementTime + 86400000) >= now) {
                this.setState({terms: true});
            } else {
                window.localStorage.termsAgreed = '';
                window.localStorage.agreementTime = '';
            }
        }
    }
    removeAllQuotations = () => {
        const db = new Database();
        db.get('images').then(res => {
            res.forEach((image, index) => {
                if (image.keywords.join(',').includes('״') || image.keywords.join(',').includes('"')) {
                    console.log(index, image.keywords.toString());
                    const keywords = [];
                    image.keywords.forEach((key, index) => {
                        if (key.includes('"') || key.includes('״')) {
                            keywords.push(key.replace('"', '').replace('״', ''))
                        } else {
                            keywords.push(key);
                        }
                    })
                    // console.log(keywords);
                    db.updateImage({
                        id: image.id,
                        keywords
                    }).then(res => console.log(index, res));
                }
            })
        })
    }
    removeAllKeyword = (keyword) => {
        const db = new Database();
        db.search(keyword).then(res => {
            res.forEach((item, index) => {
                let id = item.id;
                let keywords = item.keywords.filter(keyword => keyword !== 'רוח צהל');
                db.updateImage({
                    id, 
                    keywords
                }).then(result => console.log(result))
            })
        })
    }
    termsAgreed = () => {
        this.setState({terms: true})
        const time = new Date().getTime();
        window.localStorage.termsAgreed = 'true';
        window.localStorage.agreementTime = time;
    }
    calculateHomeItems = () => {
        const header = document.querySelector('.header').offsetHeight;
        const footer = document.querySelector('.footer').offsetHeight;
        document.querySelector('.home-items').style.minHeight = `calc(100vh - ${header}px - ${footer}px)`
    }
    componentDidMount() {
        const loadingScreen = new LoadingScreen();
        loadingScreen.startLoading();

        this.checkForTermsAgreement();

        const db = new Database();
        db.get('settings', { item: 'home-layout' }).then(homeItems => {
            const items = homeItems[0].body;
            this.setState({ homeLayout: items });
            loadingScreen.stopLoading();
        }).catch(() => {
            loadingScreen.stopLoading();
        })

        this.calculateHomeItems();
        // this.removeAllQuotations();
    }

    render() {
        return (
            <React.Fragment>
                {this.state.terms ? '' : <Terms termsAgreed={this.termsAgreed}/>}
                <Helmet>
                    <title>המאגר</title>
                </Helmet>
                <Header title="" link={false} search={true} />
                {/* <div className="search">
          <form>
            <input type="text" name="search" id="search" />
          </form>
        </div> */}
                <div className='home-items'>
                    {this.state.homeLayout.length > 0 ? 
                    this.state.homeLayout.map((item, index) => (
                        <div className="home-item" key={index}>
                            <div className="home-title">{item.title}</div>
                            {this.state.terms ? 
                            <ImageList search={item.key} onImageClick="show" home={true} collectImages={false} countSearch={false} itemIndex={index}></ImageList>
                            : ''}
                            </div>
                    )) : 
                    <h1>עמוד בית ריק? משהו כזה תגידו לי מה אתם רוצים שיהיה כתוב פה כשהעמוד בית ריק</h1>
                    }
                </div>
                <Footer openTerms={() => this.setState({terms: false})}></Footer>
            </React.Fragment>
        );
    }
}

export default Home;
