import Url from "../src/url";
import {
    SearchService
} from './getImages'

const axios = require("axios").default;
var store = require("store");
var expirePlugin = require("store/plugins/expire");
store.addPlugin(expirePlugin);

class Database {
    addUser(object) {
        return new Promise((resolve) => {
            axios
                .post(`${new Url().baseUrl}/register`, object, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                .then((response) => resolve(response.data))
                .catch((err) => resolve(err));
        });
    }

    update(collection, query, object) {
        return new Promise((resolve) => {
            // let storeKey = undefined;
            // storeKey = `${collection}-${Object.keys(query)[0]}-${Object.values(query)[0]}`;
            // if (typeof store.get(storeKey) !== 'undefined') {
            // store.remove(storeKey);
            // }

            axios
                .post(
                    `${new Url().baseUrl}/database/update`, {
                        collection,
                        query,
                        object
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.getCookie()}`,
                        },
                    }
                )
                .then((response) => resolve(response.data))
                .catch((err) => resolve(err));
        });
    }
    forceUpdate(collection, query, object) {
        return new Promise((resolve) => {
            //   let storeKey = undefined;
            //   storeKey = `${collection}-${Object.keys(query)[0]}-${
            //     Object.values(query)[0]
            //   }`;
            //   if (typeof store.get(storeKey) !== "undefined") {
            //     store.remove(storeKey);
            //   }

            axios
                .post(
                    `${new Url().baseUrl}/database/forceupdate`, {
                        collection,
                        query,
                        object
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.getCookie()}`,
                        },
                    }
                )
                .then((response) => resolve(response.data))
                .catch((err) => resolve(err));
        });
    }
    updateImage(imageObject) {
        return new Promise((resolve) => {
            //   let storeKey = undefined;
            //   storeKey = `${collection}-${Object.keys(query)[0]}-${
            //     Object.values(query)[0]
            //   }`;
            //   if (typeof store.get(storeKey) !== "undefined") {
            //     store.remove(storeKey);
            //   }

            axios
                .post(`${new Url().baseUrl}/images/update`, imageObject, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.getCookie()}`,
                    },
                })
                .then((response) => resolve(response.data))
                .catch((err) => resolve(err));
        });
    }
    delete(collection, query) {
        return new Promise((resolve) => {
            //   let storeKey = undefined;
            //   storeKey = `${collection}-${Object.keys(query)[0]}-${
            //     Object.values(query)[0]
            //   }`;
            //   if (typeof store.get(storeKey) !== "undefined") {
            //     store.remove(storeKey);
            //   }

            axios
                .post(
                    `${new Url().baseUrl}/database/delete`, {
                        collection,
                        query
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.getCookie()}`,
                        },
                    }
                )
                .then((response) => resolve(response.data))
                .catch((err) => resolve(err));
        });
    }
    calculateSearch(searchq) {
        console.log('calculating search')
        return new Promise((resolve) => {
            axios
                .post(
                    `${new Url().baseUrl}/database/calculatesearch`, {
                        searchq
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.getCookie()}`,
                        },
                    }
                )
                .then((response) => resolve(response.data))
                .catch((err) => resolve(err));
        });
    }

    create(collection, object) {
        return new Promise((resolve) => {
            axios
                .post(
                    `${new Url().baseUrl}/database/add`, {
                        collection,
                        object
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.getCookie()}`,
                        },
                    }
                ).then(res => {
                    resolve(res.data);
                }).catch(error => {
                    resolve(error);
                })
        });
    }

    async search(searchq, limit = 0) {
        // return new Promise((resolve) => {
        //     //   if (searchq != "") {
        //     //     if (typeof store.get(searchq) !== "undefined") {
        //     //       resolve(store.get(searchq));
        //     //     }
        //     //   }
        //     axios
        //         .post(
        //             `${new Url().baseUrl}/images/search`, {
        //                 searchq,
        //                 limit,
        //                 isManager: true
        //             }, {
        //                 headers: {
        //                     "Content-Type": "application/json"
        //                 }
        //             }
        //         )
        //         .then((response) => {
        //             resolve(response.data);
        //             //   if (searchq != "") {
        //             //     const today = new Date();
        //             //     const tomorrow = new Date(today);
        //             //     tomorrow.setDate(tomorrow.getDate() + 1);

        //             //     store.set(searchq, response.data, tomorrow);
        //             //   }
        //         })
        //         .catch((err) => resolve(err));
        // });
        console.log('searching  inside')
        const data = await fetch(`https://archive.search.windows.net/indexes/cosmosdb-index/docs?api-version=2020-06-30-Preview&search=${searchq}&api-key=CA8861D4A1350B02F05AE141341EE6AF`)
        const json = await data.json()
        // const searchService = new SearchService()
        // const data = await searchService.search(searchq)
        // console.log('got data')
        // const res = []
        // for await (const result of data.results) {
        //     res.push(result.document);
        // }
        // console.log('done iterating')
        return json.value
    }

    get(collection, query, limit = 0, sort = {}) {
        return new Promise((resolve) => {
            //   let storeKey = undefined;
            //   if (collection != "settings") {
            //     if (typeof Object.keys(query)[0] !== "undefined") {
            //       if (Object.keys(query)[0] !== "settings")
            //         storeKey = `${collection}-${Object.keys(query)[0]}-${
            //           Object.values(query)[0]
            //         }`;
            //       if (typeof store.get(storeKey) !== "undefined")
            //         resolve(store.get(storeKey));
            //     }
            //   }

            let url = `${new Url().baseUrl}/database/get`;
            let data = {
                query,
                collection,
                limit,
                sort
            };

            if (collection === "images") {
                url = `${new Url().baseUrl}/images/query`;
                data = {
                    query,
                    limit,
                    sort
                };
            }

            axios
                .post(url, data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.getCookie()}`,
                    },
                })
                .then((response) => {
                    resolve(response.data);
                    //   if (typeof storeKey !== "undefined") {
                    //     const today = new Date();
                    //     const tomorrow = new Date(today);
                    //     tomorrow.setDate(tomorrow.getDate() + 1);

                    //        store.set(storeKey, response.data, tomorrow);
                    //   }
                })
                .catch((err) => resolve(err));
        });
    }
    getRandomImage() {
        return new Promise((resolve) => {
            axios
                .get(
                    `${new Url().baseUrl}/randomimage`, {}, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                )
                .then((response) => resolve(response.data))
                .catch((err) => resolve(err));
        });
    }
    checkIfExists(collection, query) {
        return new Promise((resolve) => {
            axios
                .post(
                    `${new Url().baseUrl}/database/checkifexists`, {
                        query,
                        collection
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.getCookie()}`,
                        },
                    }
                )
                .then((response) => resolve(response.data))
                .catch((err) => resolve(err));
        });
    }

    getCookie(cname = "token") {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}
export default Database;