import React, { Component } from "react";
import { Link } from "react-router-dom";
import Database from "../src/database";
import Redirect from "../src/redirect";
import Files from "../src/files";
import {Button, Collapse} from '@material-ui/core';

class Header extends Component {
    state = {
        searchQ: '',
        advancedSearch: false,
        advancedProps: {
            including: '',
            notincluding: '',
        },
        randomImage: '/assets/images/colors/blue.png',
        color: "white",
        which: "dotz",
        path: "/assets/images/logo",
        extention: ".svg",
        link: true,
        customLink: '',
        search: false,
        small: false,
        searchUrl: 'search',
        backgroundImage: true,
        searchSuggestions: [],
        update: 0,
        backgroundColor: 'rgb(0, 18, 41)',
        back: false,
        backTo: 'עמוד בית',
        backToHref: '/',
        darkMode: false,
        ...this.props,
    };

    constructor(props) {
    super(props);

        this.getSearchSuggestions = this.getSearchSuggestions.bind(this);
    }

    shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
    
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
        
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
        
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
    
        return array;
    }
    componentDidMount() {
        const db = new Database();
        db.getRandomImage().then(img => {
            this.setState({ randomImage: img })
        });

        if (this.state.search) {
            this.getSearchSuggestions();
        }

        if (window.localStorage.darkMode === 'true') {
            this.setState({darkMode: true})
            document.querySelector('body').classList.add('darkmode');
        }
        if (this.props.searchedText) {
            console.log(this.props.searchedText)
            this.setState({searchQ: this.props.searchedText})
        }
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.advancedProps) {
            this.setState({advancedProps: nextProps.advancedProps})
        }
    }
    getSearchSuggestions() {
    const db = new Database();
    // db.get('search', {}, 3, { searched: -1 }).then(res => {
    //     if (res.forEach === undefined) {
    //         alert('error');
    //         window.replace('/error');
    //         return;
    //     }
    //     let searchSuggestionsArray = [];
    //     res.forEach(search => {
    //         searchSuggestionsArray.push(search.searchq);
    //     })
    //     const searchSuggestions = this.shuffle(searchSuggestionsArray)
    //     searchSuggestions.splice(6);
    //     this.setState({ 
    //         searchSuggestions,
    //         update: this.state.update++
    //     });
    // }).catch(error => {
    //     alert(error);
    // })
    db.get('images').then(res => {
        const keywords = [];
        res.forEach(image => {
            keywords.push(...image.keywords)
        })
        const searchSuggestions = this.shuffle(keywords);
        searchSuggestions.splice(6);
        this.setState({searchSuggestions, update: this.state.update++})
    }).catch(error => {
        console.log(error);
    })
    }
    searchInputOnInput = (searchQ) => {
        this.setState({searchQ})
    }
    advancedInputHandler = (e) => {
        const advancedProps = {
            including: e.target.id === 'advanced-including' ? e.target.value : this.state.advancedProps.including,
            notincluding: e.target.id === 'advanced-not-including' ? e.target.value : this.state.advancedProps.notincluding,
        }
        console.log(advancedProps);
        this.setState({advancedProps}, () => {
            console.log(this.state.advancedProps);
        })
    }
    search(e) {
        e.preventDefault();
        const advancedProps = {
            including: this.state.advancedProps.including && this.state.advancedProps.including.length >= 1 ? `including=${this.state.advancedProps.including}` : undefined,
            notincluding: this.state.advancedProps.notincluding && this.state.advancedProps.notincluding.length >= 1 ? `notincluding=${this.state.advancedProps.notincluding}` : undefined,
        }
        const parameters = `${advancedProps.including || advancedProps.notincluding ? '?' : ''}${advancedProps.including ? advancedProps.including : ''}${advancedProps.including && advancedProps.notincluding ? '&' : ''}${advancedProps.notincluding ? advancedProps.notincluding : ''}`
        window.location.replace(`/search/${this.state.searchQ}${parameters}`);
    }
    toggleDarkMode = () => {
        const button = document.querySelector('.light-dark-mode');
        const body = document.querySelector('body');

        button.classList.add('animation');
        setTimeout(() => {
            this.setState({darkMode: !this.state.darkMode})
            button.classList.remove('animation');
        }, 300)

        if (!body.classList.toString().includes('darkmode')) {
            body.classList.add('darkmode');
            window.localStorage.darkMode = true;
        } else {   
            body.classList.remove('darkmode');
            window.localStorage.darkMode = undefined;
        }
    }

    render() {
        return (
            <div className={`header${(this.state.small) ? ' small' : ''}${this.state.search ? ' search' : ''}`} style={(this.state.backgroundImage) ? {background: `linear-gradient(rgba(0, 18, 41, 0.4), rgba(0, 18, 41, 0.4)), url(${this.state.randomImage}), center`
            } : { backgroundColor: (typeof this.props.backgroundColor !== 'undefined') ? this.props.backgroundColor : this.state.backgroundColor }}>
                <button className='light-dark-mode' onClick={this.toggleDarkMode}>
                    {this.state.darkMode ? 
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.45 525.45"><defs></defs><circle cx="262.73" cy="262.73" r="135.26"/><path class="cls-1" d="M546.36,435H465.69a14,14,0,0,1,0-28h80.67a14,14,0,0,1,0,28Z" transform="translate(-34.91 -158.22)"/><path class="cls-1" d="M129.59,435H48.91a14,14,0,0,1,0-28h80.68a14,14,0,0,1,0,28Z" transform="translate(-34.91 -158.22)"/><path class="cls-1" d="M297.64,683.67a14,14,0,0,1-14-14V589a14,14,0,0,1,28,0v80.67A14,14,0,0,1,297.64,683.67Z" transform="translate(-34.91 -158.22)"/><path class="cls-1" d="M297.64,266.89a14,14,0,0,1-14-14V172.22a14,14,0,0,1,28,0v80.67A14,14,0,0,1,297.64,266.89Z" transform="translate(-34.91 -158.22)"/><path class="cls-1" d="M473.51,610.82a14,14,0,0,1-9.9-4.1l-57-57.05a14,14,0,0,1,19.8-19.79l57,57a14,14,0,0,1-9.9,23.9Z" transform="translate(-34.91 -158.22)"/><path class="cls-1" d="M178.81,316.12a14,14,0,0,1-9.9-4.1l-57-57a14,14,0,1,1,19.8-19.8l57.05,57.05a14,14,0,0,1-9.9,23.9Z" transform="translate(-34.91 -158.22)"/><path class="cls-1" d="M416.47,316.12a14,14,0,0,1-9.9-23.9l57-57.05a14,14,0,0,1,19.8,19.8l-57,57A14,14,0,0,1,416.47,316.12Z" transform="translate(-34.91 -158.22)"/><path class="cls-1" d="M121.76,610.82a14,14,0,0,1-9.9-23.9l57.05-57a14,14,0,0,1,19.8,19.79l-57.05,57.05A14,14,0,0,1,121.76,610.82Z" transform="translate(-34.91 -158.22)"/></svg>
                    :
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.17 264.47"><path d="M365.4,508.56A135.27,135.27,0,0,1,257.49,291.74,135.28,135.28,0,1,0,405.55,502.5,135.17,135.17,0,0,1,365.4,508.56Z" transform="translate(-162.37 -291.74)"/></svg>
                    }
                </button>
                {this.state.back ? <a className='back-button' href={this.state.backToHref}>❮ חזרה ל{this.state.backTo}</a> : ''}
                <Link to={(this.state.link) ? ((this.props.customLink != '') ? this.props.customLink : '../') : '#'} className={(this.state.small || this.state.search) ? 'small' : ''}>
                    <img className={this.state.small && this.state.search ? 'logo-small logo' : `logo ${this.state.small && !this.state.search ? 'logo-extra-small' : 'logo'}`} src={this.state.small ? "/assets/images/logo/logo-small.svg" : "/assets/images/logo/logo.svg"} style={(this.props.noLogo) ? {display: 'none'} : null}
                    ></img>{" "}
                    <h1 className={`header-title${(this.state.small || this.state.search) ? ' small' : ''}`}>{this.props.title}</h1>
                </Link>
                <div className={`search-area${(this.state.small) ? ' small' : ''}${this.state.search ? '' : ' hide'}`}>
                    <form onSubmit={(e) => { this.search(e) }}>
                        <input type="text" name="search" id="search" className="search-input" onInput={(e) => this.searchInputOnInput(e.target.value)} value={this.state.searchQ} />
                        <Button type='submit' name='submit' classes={{root: `search-submit ${this.state.searchQ.length >= 1 ? 'in-place' : ''}`}}>חיפוש</Button>
                    </form>
                    <Button classes={{root: 'advanced-search-button', label: `inner-label ${this.state.advancedSearch ? 'opened' : ''}`}} variant='contained' onClick={() => this.setState({advancedSearch: !this.state.advancedSearch})}>חיפוש מתקדם</Button>
                    <Collapse in={this.state.advancedSearch} classes={{wrapperInner: 'advanced-area'}}>
                        <div className='advanced-input-container'>
                            <label htmlFor='advanced-including'>כולל את המילים:</label>
                            <input onInput={this.advancedInputHandler} id='advanced-including' name='advanced-including' placeholder='ערכים מרובים מופרדים בפסיקים' value={this.state.advancedProps.including}></input>
                        </div>
                        <div className='advanced-input-container'>
                            <label htmlFor='advanced-not-including'>לא כולל את המילים:</label>
                            <input onInput={this.advancedInputHandler} id='advanced-not-including' name='advanced-including' placeholder='ערכים מרובים מופרדים בפסיקים' value={this.state.advancedProps.notincluding}></input>
                        </div>
                    </Collapse>
                    <div className={`search-des`} >
                        הצעות: <span style={{fontWeight: '800'}} className={`${this.state.searchSuggestions.length !== 0 ? 'hide' : ''}`}>טוען...</span>{
                        this.state.searchSuggestions.map((searchq, index) =>
                            <React.Fragment key={index}>
                            <strong onClick={() => new Redirect(`../../search/${searchq}`)}>{searchq}</strong>
                            {index !== this.state.searchSuggestions.length - 1 ? ', ' : ''}
                            </React.Fragment>
                        )
                        }
                    </div>
                </div>
            </div >
        );
  }
}

export default Header;
