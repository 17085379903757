import Url from "../src/url";

const axios = require('axios').default;
var store = require('store');
var expirePlugin = require('store/plugins/expire')

class Files {

    upload(file, id, collectionId) {

        return new Promise((resolve) => {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('id', id);
            formData.append('collectionId', collectionId);
            console.log(formData);
            axios.post(
                `${new Url().baseUrl}/uploadimage`,
                formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getCookie()}`
                    }
                }
            ).then(response => {
                resolve(response.data)
                console.log(response);
            }).catch(err => resolve(err));
        });
    }

    getProgress(id) {

        return new Promise((resolve) => {



            var formData = new FormData();

            axios.get(
                `${new Url().baseUrl}/upload/progress/${id}`,
                formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getCookie()}`
                    }
                }
            ).then(response => resolve(response.data)).catch(err => resolve(err));
        });
    }

    getLink(path) {

        return new Promise((resolve) => {
            // store.addPlugin(expirePlugin)
            // const newPath = path.split('/').join('~');

            // if (typeof store.get(id) !== 'undefined')
            //     resolve(store.get(id));


            // axios.get(
            //     `${new Url().baseUrl}/getimage/${newPath}`,
            //     { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.getCookie()}` } }
            // ).then(response => {
            //     resolve(response.data)
            //     var startDate = new Date();
            //     startDate.setMinutes(startDate.getMinutes() - 20);
            //     var expiryDate = new Date(startDate);
            //     expiryDate.setMinutes(startDate.getMinutes() + 60);

            //     store.set(id, response.data, expiryDate)
            // }).catch(err => resolve(err));
            resolve(`https://idf-archive.azureedge.net/${path}`)
        });
    }

    delete(path) {
        return new Promise((resolve) => {

            axios.post(
                `${new Url().baseUrl}/deletephoto`, {
                    path
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getCookie()}`
                    }
                }
            ).then(response => resolve(response.data)).catch(err => resolve(err));
        });
    }

    getImageColors(imageUrl) {
        return new Promise((resolve) => {
            axios.post(
                `${new Url().baseUrl}/getimagecolor/`, {
                    file: imageUrl
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getCookie()}`
                    }
                }
            ).then(response => {
                resolve(response.data);
            }).catch(err => resolve(err));
        });
    }


    analyze(imageUrl) {
        return new Promise((resolve) => {

            axios.post(
                `${new Url().baseUrl}/analyze`, {
                    image: imageUrl
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getCookie()}`
                    }
                }
            ).then(response => resolve(response.data)).catch(err => resolve(err));
        });
    }

    getFaceName(faceId) {
        return new Promise((resolve) => {

            axios.post(
                `${new Url().baseUrl}/person/name`, {
                    faceId
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.getCookie()}`
                    }
                }
            ).then(response => resolve(response.data)).catch(err => resolve(err));
        });
    }

    getCookie(cname = 'token') {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}
export default Files;