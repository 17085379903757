import React, { Component } from "react";
import Confirm from "../src/confirm";
import Alert from "../src/alert";
import Database from "../src/database";
import Redirect from "../src/redirect";
class ImageGrid extends Component {
    state = {
        selected: [],
        images: [],
        selection: true,
        multipleSelection: false,
        currentImage: null,
        collection: '',
        edit: false,
        imageId: 0,
        editObject: undefined,
        blockImages: false,
        newUpload: false,
    }
    imageClickHandler = async (image, id, object, rotate) => {
        if (this.state.blockImages) {
            return;
        }
        let remove = false;
        if (this.state.selection) {
            let tempSelected = [...this.state.selected];
            if (tempSelected.includes(id)) {
                tempSelected = tempSelected.filter((checkId) => checkId !== id);
                remove = true;
            } else {
                if (this.state.multipleSelection) {
                    tempSelected.push(id);
                } else {
                    tempSelected = [id]
                    this.setState({editObject: []})
                }
            }
            this.setState({ selected: tempSelected }, () => {
                if (remove) {
                    console.log(this.state.editObject);
                    let editObject = this.state.editObject.filter(object => object.id !== id);
                    this.setState({editObject}, () => {
                        this.props.openEditForm(this.state.selected, this.state.editObject)
                    })
                    return;
                }
                if (!this.state.editObject) {
                    this.setState({editObject: [object]}, () => {
                        this.props.openEditForm(this.state.selected, this.state.editObject)
                    })
                } else if (this.state.editObject) {
                    let editObject = [...this.state.editObject];
                    editObject.push(object);
                    this.setState({editObject}, () => {
                        this.props.openEditForm(this.state.selected, this.state.editObject)
                    })
                }
            });
        } else {
            this.setState({ currentImage: {image, id, rotate} }, () => {
                setTimeout(() => {
                    document.querySelector('.image-popup').style.opacity = '1';
                }, 1)
            });
        }
    }
    close = () => {
        document.querySelector('.image-popup').style.opacity = '0';
        setTimeout(() => {
            this.setState({ currentImage: null });
        }, 400)
    }
    selectionChangedHandler = (selection) => {
        this.setState({selection}, () => {
            this.props.changeSelection(selection);
        });
        if (!selection) {
            this.setState({
                selected: [],
                editObject: [],
            });
        }
        if (!selection && this.state.multipleSelection) {
            this.setState({multipleSelection: false})
        }
    }
    multipleSelectionChangeHandler = (multipleSelection) => {
        this.setState({multipleSelection});
        if (!multipleSelection) {
            this.setState({
                selected: [],
                editObject: [],
            }, () => {
                this.props.cancelMultipleSelection()
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ 
            images: nextProps.images,
            selection: nextProps.selection,
        });
        if (nextProps.newUpload) {
            this.setState({newUpload: nextProps.newUpload})
        }
    }
    deleteImages = async () => {
        const amount = this.state.selected.length;
        let count = 0;
        const shouldDelete = await new Confirm(
            "בטוח?",
            `אם הפעולה תאושר, ימחקו ${this.state.selected.length} תמונות. זוהי פעולה שלא ניתן לשחזר.`,
            false
        ).show();
        if (shouldDelete) {
            let db = new Database();
            this.state.selected.forEach(async (id) => {
                await db.delete('images', {id}).then(res => {
                    count++;
                    this.props.deleteImage(id)
                    if (count === amount) {
                        new Alert(
                            'התמונות נמחקו בהצלחה',
                            ''
                        )
                    }
                })
            })
            // window.location.reload();
            this.setState({ selected: [] });
        }
    }
    deleteImage = async () => {
        const shouldDelete = await new Confirm(
            "בטוח?",
            `אם הפעולה תאושר, התמונה תימחק. זוהי פעולה שלא ניתן לשחזר.`
        ).show();
        if (shouldDelete) {
            let db = new Database();
            await db.delete("images", { id: this.state.currentImage.id });
            this.setState({ selected: [] });
            this.close();
            new Alert(
                'התמונה נמחקה בהצלחה',
                '',
            )
        }
        const imageIndex = this.state.images.findIndex(image => image.id === this.state.currentImage.id);
        let images = [...this.state.images];
        images.splice(imageIndex, 1);
        this.setState({images});
    }
    resetSelected = () => {
        this.setState({
            selected: [],
            editObject: [],
        })
    }
    rotateImage = (rotate) => {
        const db = new Database();
        const id = this.state.currentImage.id;
        let images = [...this.state.images];
        let index = this.state.images.findIndex(image => image.id === id);
        let currentImage = {...this.state.currentImage};

        console.log('node');
        db.updateImage({
            id,
            rotate,
        }).then(res => {
            console.log(res);
            // window.location.reload();
            images[index].rotate = rotate;
            currentImage.rotate = rotate;
            this.setState({images, currentImage});
            this.close();
        })
    }
    selectAll = () => {
        let selected = [];
        let editObject = []
        this.state.images.forEach((image, index) => {
            selected.push(image.id);
            editObject.push({
                id: image.id,
                title: image.title,
                uploader: image.uploader,
                who: image.who,
                corps: image.corps,
                keywords: image.keywords,
            })
        })
        if (this.state.selected.length === selected.length) {
            this.setState({selected: [], editObject: []}, () => {
                this.props.openEditForm(this.state.selected, this.state.editObject)
            })
        } else {
            this.setState({selected, editObject}, () => {
                this.props.openEditForm(this.state.selected, this.state.editObject)
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {typeof this.state.images !== "undefined" &&
                    this.state.images.length > 0 ? (
                        <div className="grid-options">
                            <div>
                                <button className={`select-all`} onClick={this.selectAll}>בחר הכל</button>
                                <input type="checkbox" id="selection" checked={this.state.selection} onChange={(e) => this.selectionChangedHandler(e.target.checked)}/>
                                <label htmlFor="selection" className={`radio-label ${this.state.selection ? 'checked' : ''}`}>בחירה</label>
                                <input type="checkbox" id="multiple" checked={this.state.multipleSelection} onChange={(e) => this.multipleSelectionChangeHandler(e.target.checked) }/>
                                <label htmlFor="multiple" className={`radio-label ${this.state.multipleSelection ? 'checked' : this.state.selection ?'' : 'disabled'}`}>בחירה מרובה</label>
                            </div>
                            <div>
                                <button className={`reset ${this.props.resetButtonDisabled ? '' : 'disabled'}`} onClick={this.props.resetImages}>איפוס</button>
                                <button className={`delete delete-animation ${this.state.selected.length > 0 ? '' : 'disabled'}`} onClick={this.deleteImages}>מחיקה</button>
                            </div>
                        </div>
                    ) : null}
                <div className="image-grid">
                    {this.state.images.length === 0 && !this.state.newUpload ? <h2>טוען...</h2> : ''}
                    {this.state.images.map((image, index) => (
                        <div className={`grid-image ${this.state.selected.includes(image.id) ? "selected " : ""} ${image.rotate && image.rotate !== 'false' ? (image.rotate === 'right' ? 'rotate-right' : 'rotate-left' ) : '' }`} key={index} >
                            <img
                                src={image.thumbnail ? image.thumbnail : image.url}
                                id={image.id}
                                onClick={() => this.imageClickHandler(
                                    image.cover,
                                    image.id,
                                    {
                                        id: image.id,
                                        title: image.title,
                                        uploader: image.uploader,
                                        who: image.who,
                                        corps: image.corps,
                                        tags: image.tags,
                                        keywords: image.keywords,
                                    },
                                    image.rotate,
                                )}
                            />
                        </div>
                    ))}
                </div>
                {this.state.currentImage !== null ? (
                    <div className={`image-popup`}>
                        <div className="exit" onClick={this.close}>
                            <img src="/assets/icons/exit.svg" />
                        </div>
                        <div className={this.state.currentImage.rotate && this.state.currentImage.rotate !== 'false' ? (this.state.currentImage.rotate === 'right' ? 'rotate-right' : 'rotate-left' ) : '' }>
                            <img src={this.state.currentImage.image}/>
                        </div>
                        <div className='image-popup-buttons'>
                            <button className="delete" onClick={this.deleteImage}>מחיקת תמונה</button>
                            <a href={this.state.currentImage.image} className="image-options download" download>
                                <button>הורדת התמונה</button>
                            </a>
                            <button className='rotate' onClick={() => this.rotateImage('right')} >&#10227;</button>
                            <button className='rotate' onClick={() => this.rotateImage(false)}>&#8857;</button>
                            <button className='rotate' onClick={() => this.rotateImage('left')} >&#10226;</button>
                        </div>
                        <div className='image-popup-close' onClick={this.close}></div>
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}

export default ImageGrid;
