import React, { Component } from "react";
import ImageGrid from "./imageGrid";
import Database from "../src/database";
import Files from "../src/files";
import Auth from "../src/auth";
import Alert from "../src/alert";
import Confirm from "../src/confirm";
import Helmet from "react-helmet";
import LoadingScreen from "../src/loading";
import Redirect from "../src/redirect";
import UploadProgress from "./uploadprogress";
import ReactImageGallery from "react-image-gallery";
import ImageList from './imageList';

var uniqid = require("uniqid");

class UploadForm extends Component {
    constructor(props) {
        super(props);
        this.imageGrid = React.createRef();
    }
    state = {
        collectionId: uniqid(),
        images: [],
        thumbnails: [],
        newImages: [],
        newUpload: false,
        ids: [], // the ids acts like refrences to the images of the current collection
        selectedIds: [],
        originalTitle: undefined,
        title: '',
        uploader: '',
        who: '',
        whoChanged: false,
        whoOriginal: undefined,
        corps: '',
        corpsChanged: false,
        corpsOriginal: undefined,
        tags: '',
        tagsChanged: false,
        tagsOriginal: undefined,
        keywords: '',
        keywordsChanged: false,
        keywordsOriginal: undefined,
        informationChanged: false,
        resetButtonDisabled: true,
        edit: false,
        uploadFileIndex: { index: 0 },
        autoDetectedData: {},
        isPublic: false,
        isPublicOriginal: undefined,
        savebuttonDisabled: true,
        selectedFilesCount: 0,
        isCommander: false,
        newProperties: {},
        selected: false,
        readyToEdit: 'מוכן לעריכה?',
        existingImages: '',
        selection: true,
        changeInInformation: false,
        titleEdited: false,
        imagesChanged: [],
        readyToReload: false,
    }
    async componentDidMount() {
        // console.log(this.imageGrid);
        //Checks if the user have permissions to be here
        const auth = new Auth();
        this.user = await auth.user();
        this.setState({uploader: this.user.name});
        let commanderCheck = await auth.hasPermission("commander");

        this.setState({
            isCommander: commanderCheck,
            newUpload: this.props.newUpload
        });
    }
    disableSaveButton = () => {
        const titleChanged = this.state.originalTitle !== undefined && this.state.originalTitle !== this.state.title;
        const isPublicChanged = this.state.isPublicOriginal !== undefined && this.state.isPublic !== this.state.isPublicOriginal;
        const informationChanged = this.state.informationChanged;
        if (titleChanged || isPublicChanged || informationChanged) {
            this.setState({savebuttonDisabled: false})
        } else if (!titleChanged && !isPublicChanged && !informationChanged) {
            this.setState({savebuttonDisabled: true})
        }
    }
    disableResetButton = () => {
        const informationChanged = this.state.informationChanged;
        const selectedIds = [...this.state.selectedIds];
        const imagesChanged = [...this.state.imagesChanged];
        // console.log(selectedIds, imagesChanged);
        let selectedImagesChanged = false;
        imagesChanged.forEach(image => {
            selectedIds.forEach(id => {
                if (image === id) {
                    selectedImagesChanged = true;
                }
            })
        })
        // console.log(selectedImagesChanged, informationChanged);
        const boolean = informationChanged && selectedImagesChanged;
        this.setState({resetButtonDisabled: !boolean})
    }
    checkForEmptyInformation = () => {
        const images = this.state.images;
        let counter = 0;
        const boolean = images.every(image => {
            return (!image.whoOriginal || image.whoOriginal == image.who) && (!image.corpsOriginal || image.corpsOriginal == image.corps) && (!image.keywordsOriginal || image.keywordsOriginal == image.keywords) && (!image.tagsOriginal || image.tagsOriginal == image.tags) 
        })
        this.setState({informationChanged: !boolean})
    }
    checkForChanges = () => {
        let imagesChanged = [...this.state.imagesChanged];
        this.state.images.forEach(image => {
            let keywordsNotChanged = true;
            if (image.keywordsOriginal) {
                keywordsNotChanged = image.keywords.every((keyword, index) => {
                    return keyword === image.keywordsOriginal[index];

                })
                // console.log(keywordsNotChanged);
            }
            if ((image.whoOriginal && image.whoOriginal != image.who) || (image.corpsOriginal && image.corpsOriginal != image.corps) || !keywordsNotChanged) {
                const exists = imagesChanged.find(id => {
                    return id === image.id;
                })
                if (!exists) {
                    imagesChanged.push(image.id)
                    this.setState({imagesChanged}, () => {
                        this.disableResetButton();
                    })
                }
                return;
            } else {
                const index = imagesChanged.findIndex(id => {
                    return id === image.id
                })
                if (index !== -1) {
                    imagesChanged.splice(index, 1);
                    this.setState({imagesChanged}, () => {
                        this.disableResetButton();
                    })
                    return;
                }
            }
        })
    }
    titleChangeHandler = (title) => {
        if (this.state.originalTitle === undefined) {
            this.setState({originalTitle:  this.state.title});
        }
        this.setState({
            title, 
            selection: false,
            titleEdited: true,
        }, () => {
            this.disableSaveButton();
        });
    }
    whoChangeHandler = async (who) => {
        this.setState({
            who, 
            informationChanged: true
        }, () => {
            this.disableSaveButton();
        });
        let selectedImages = [];
        if (this.state.selectedIds.length < 1) {
            selectedImages = [...this.state.images];
        } else {
            this.state.images.forEach(image =>  {
                this.state.selectedIds.forEach(id => {
                    if (image.id ===  id) {
                        selectedImages.push(image)
                    }
                })
            })
        }
        selectedImages.forEach(image => {
            if (!image.whoOriginal) {
                image.whoOriginal = image.who;
            }
            image.who = this.commasToArray(who);
        })
        this.checkForEmptyInformation();
        this.checkForChanges();
    }
    corpsChangeHandler = (corps) => {
        this.setState({
            corps,
            informationChanged: true
        }, () => {
            this.disableSaveButton();
        });
        let selectedImages = [];
        if (this.state.selectedIds.length < 1) {
            selectedImages = [...this.state.images];
        } else {
            this.state.images.forEach(image =>  {
                this.state.selectedIds.forEach(id => {
                    if (image.id ===  id) {
                        selectedImages.push(image)
                    }
                })
            })
        }
        selectedImages.forEach(image => {
            if (!image.corpsOriginal) {
                image.corpsOriginal = image.corps;
            }
            image.corps = this.commasToArray(corps);
        })
        this.checkForEmptyInformation();
        this.checkForChanges();
    }
    tagsChangeHandler = (tags) => {
        this.setState({
            tags,
            informationChanged: true,
        }, () => {
            this.disableSaveButton();
            console.log(this.state.informationChanged)
        });
        let selectedImages = [];
        if (this.state.selectedIds.length < 1) {
            selectedImages = [...this.state.images];
        } else {
            this.state.images.forEach(image =>  {
                this.state.selectedIds.forEach(id => {
                    if (image.id ===  id) {
                        selectedImages.push(image)
                    }
                })
            })
        }
        selectedImages.forEach(image => {
            if (!image.tagsOriginal) {
                if (image.tags) {
                    image.tagsOriginal = image.tags;
                } else {
                    image.tagsOriginal = "";
                }
            }
            setTimeout(() => {
                image.tags = this.commasToArray(tags);
            }, 500);
        })
        this.checkForEmptyInformation();
        this.checkForChanges();
    }
    keywordsChangeHandler = (keywords) => {
        this.setState({
            keywords,
            informationChanged: true
        }, () => {
            this.disableSaveButton();
        });
        let selectedImages = [];
        if (this.state.selectedIds.length < 1) {
            selectedImages = [...this.state.images];
        } else {
            this.state.images.forEach(image =>  {
                this.state.selectedIds.forEach(id => {
                    if (image.id ===  id) {
                        selectedImages.push(image)
                    }
                })
            })
        }
        selectedImages.forEach(image => {
            if (!image.keywordsOriginal) {
                image.keywordsOriginal = image.keywords;
            }
            image.keywords = this.commasToArray(keywords);
        })
        this.checkForEmptyInformation();
        this.checkForChanges();
    }
    componentWillReceiveProps(nextProps) {
        //Checks if we are in edit mode, or if we are adding new image
        if (nextProps.edit) {
            this.setState({ edit: true });
            if (nextProps.collectionId !== 0) {
                //There is an image selected

                //connects to the database
                const db = new Database();

                //getting image with the id of nextProps.imageId
                db.get("images", { collectionId: nextProps.collectionId }).then((image) => {
                    if (image.length === 0) {
                        new Alert('לא נמצא אוסף', 'אנא נסה להיכנס לאוסף שנית מלוח  הניהול', false,  '; window.open(`/manage`, `_self`)');
                        return;
                    }
                    //updated the "collectionId" so we can find the rest of the images in this collecion and edit them as well
                    this.setState({ collectionId: image[0].collectionId });

                    //getting images from the database, where the collectionId is equal to the id of image[0].collectionId
                    db.get("images", {
                        collectionId: image[0].collectionId,
                    }).then((collectionsImages) => {
                        //collectionsImages" is an array that contains all the results
                        collectionsImages.forEach((image) => {
                            //"image" is an object

                            //updating the images state with the result
                            let images = [...this.state.images];
                            images.push({
                                url: image.image.url,
                                id: image.id,
                                title: image.title,
                                uploader: image.uploader,
                                who: image.who,
                                corps: image.corps,
                                keywords: image.keywords,
                                thumbnail: image.thumbnail.url,
                                cover: image.cover.url,
                                rotate: image.rotate,
                            });
                            this.setState({images, existingImages: images.length});

                            //updating the ids state with the result image's ids.
                            let ids = [...this.state.ids];
                            ids.push(image.id);
                            this.setState({ ids });

                            //Updating the form with the info that was recived
                            this.setState({ title: image.title === undefined ? '' : image.title });
                            this.setState({ isPublic: image.visible });
                            // this.setState({ who: image.who.join(", ") });
                            // this.setState({ corps: image.corps.join(", ") });
                            // this.setState({ keywords: image.keywords.join(", ") });
                            this.setState({ uploader: image.uploader });
                        });
                    });
                });
            }
        } else {
            //If no image is recevied via props, this will switch to new image mode instead of editing image mode.
            this.setState({ edit: false });
        }
        this.setState({newUpload: nextProps.newUpload})
    }
    //makes a string with commas into an array of the values seperated by the commas
    commasToArray = (string) => {
        return string.split(",").map((item) => item.trim())
    }
    uploadFile = async () => {
        this.imageGrid.current.setState({blockImages: true})
        //Create the collection if it doesn't exist
        const dbs = new Database();
        dbs.checkIfExists("collections", { id: this.state.collectionId }).then((exists) => {
            if (!exists) {
                dbs.create("collections", { id: this.state.collectionId }).then((done) => {

                }).catch((err) => {
                    new Alert("אופס... ישנה בעיה", err);
                    console.log(err);
                });
            }
        }).catch((err) => {
            new Alert("אופס... ישנה בעיה", err);
            console.log(err);
        });

        //Getting all the files selected with the file input
        const file = document.querySelector("[type=file]").files;
        let tempselectedFilesCount = this.state.selectedFilesCount + file.length;
        this.setState({ selectedFilesCount: tempselectedFilesCount });

        //If no file is selected, the function stops here
        if (typeof file == "undefined") return;

        //update the selected files count
        let counter = 0;
        let imagesUpdated = 0;
        //This for loop is like forEach but for objects
        for (let [key, value] of Object.entries(file)) {
            let uploadFileIndex = { ...this.state.uploadFileIndex };
            const thisFileIndex = uploadFileIndex.index;
            uploadFileIndex++;
            this.setState({ uploadFileIndex });
            counter++;
            //Calling the files class wich have upload files functions in it
            const files = new Files();

            //Setting the file id as the current timestamp
            const fileId = uniqid();

            //Getting the current file
            const singleFile = file[key];

            //Reading the file so it can be shown on the image carusel
            var reader = new FileReader();
            reader.onload = this.readerOnLoad;
            reader.readAsDataURL(singleFile);

            // Uploading the file to the server
            files.upload(singleFile, fileId, this.state.collectionId).then(async (response) => {
                console.log(response);
                //Setting the ids state
                let ids = [...this.state.ids];
                ids.push(response.id);
                this.setState({ ids });

                //getting the image src url
                setTimeout(() => {
                    // console.log(response);
                }, 5000);
                const link = response.url;

                //updating the images state
                //the images state will be presented as the image gallery
                let images = [...this.state.images];
                images.push({ url: link, id: response.id });
                this.setState({ images });

                //getting the auto analized data
                files.analyze(link).then((data) => {
                    const tempData = data;
                    const detectedFaces = [];

                    //cheking if the AI can identify the name
                    if ("faces" in data) {
                        data.faces.forEach((face) => {
                            files
                                .getFaceName(face.faceId)
                                .then((name) => {
                                    detectedFaces.push(name);
                                })
                                .catch((err) => {
                                    new Alert("אופס... ישנה בעיה", err);
                                    console.log(err);
                                });
                        });
                    }
                    //adding the faces to the auto detected data
                    tempData.faces = detectedFaces;
                    this.setState({ autoDetectedData: tempData });
                });
                if (!this.state.newUpload) {
                    const db = new Database();
                    let newId = undefined;
                    const interval = setInterval(async () => {
                        await db.get('images', {'image.id': response.id}).then(res => {
                            // console.log(res);
                            if (res.length > 0) {
                                clearInterval(interval);
                                newId = res[0].id
                                db.updateImage({
                                    id: newId,
                                    title: this.state.images[0].title,
                                }).then(response => {
                                    // console.log(response);
                                    imagesUpdated++;
                                    console.log(imagesUpdated, Object.entries(file).length)
                                    if (imagesUpdated === Object.entries(file).length) {
                                        this.reloadToCollection();
                                    }
                                })
                            }
                        })
                    }, 500)
                }
            })
                .catch((err) => {
                    new Alert("אופס... ישנה בעיה", err);
                    console.log(err);
                });
        }
    }
    openFileDialog() {
        //Trigger the file input button
        document.querySelector("[type=file]").click();
    }
    isPublicChangeHandler = (isPublic) => {
        if (this.state.isPublicOriginal === undefined) {
            this.setState({
                isPublicOriginal: this.state.isPublic,
                isPublic,
            }, () => {
                this.disableSaveButton();

            });
        } else {
            this.setState({
                isPublic,
            }, () => {
                this.disableSaveButton();

            });
        }
    }
    changeSelection = (selection) => {
        // console.log(selection);
        this.setState({
            selection,
        });
        if (!selection) {
            this.setState({
                selected: false,
                selectedIds: [],
                who: '',
                corps: '',
                keywords: '',
            })
        }
        if (this.state.titleEdited) {
            this.setState({title: this.state.originalTitle})
        }
    }
    cancelMultipleSelection = () => {
        this.setState({
            who: '',
            corps: '',
            keywords: '',
            selectedIds: [],
            
        })
    }
    saveImages = async () => {
        const imageGridScrollTop = document.querySelector('body').scrollTop;
        this.setState({
            imageGridScrollTop
        })
        //Disabling the save button so it wont be triggering this function twice
        await this.setState({
            savebuttonDisabled: true,
            titleEdited: false,
        });
        let ids = [];
        let changedImagesInSelected = 0;
        
        this.state.selectedIds.forEach(id => {
            this.state.imagesChanged.forEach(image => {
                if (image === id) {
                    changedImagesInSelected++;
                }
            })
        })
        if ((this.state.selectedIds.length < this.state.imagesChanged.length && this.state.selectedIds.length > 0) || (this.state.selectedIds.length > 0 && changedImagesInSelected === 0)) {
            const confirm = new Confirm(
                `שים לב, בוצעו שינויים ב-${this.state.imagesChanged.length} תמונות`,
                'האם את/ה רוצה לשמור את כל השינויים או רק את התמונות המסומנות?',
                { yes: 'כל השינויים', no: 'תמונות מסומנות' },
                true
            );
            const saveAll = await confirm.show();
            if (saveAll) {
                ids = [...this.state.ids]
            } else {
                ids = [...this.state.selectedIds]
            }
        } else {
            //getting the ids from the state
            if (!this.state.selected) {
                ids = [...this.state.ids];
            } else if (this.state.selected) {
                ids = [...this.state.selectedIds];
            }
            new Alert(
                'טוען...',
                '',
                true
            )
        }
        //running through the ids to save each image individually
        if (this.state.selectedIds.length > 1) {
            let images = [];
            ids.forEach((id, index) => {
                images[index] = this.state.images.find(image => image.id === id)
            })
            const originalValues = [];
            if (images[0].whoOriginal && images[0].whoOriginal.length >= 1) {
                originalValues[0] = images[0].whoOriginal.join(', ')
            } else {
                originalValues[0] = '';
            }
            if (images[0].corpsOriginal && images[0].corpsOriginal.length >= 1) {
                originalValues[1] = images[0].corpsOriginal.join(', ')
            } else {
                originalValues[1] = '';
            }
            if (images[0].keywordsOriginal && images[0].keywordsOriginal.length >= 1) {
                originalValues[2] = images[0].keywordsOriginal.join(', ')
            } else {
                originalValues[2] = '';
            }
            const allMatch = [
                images.every(image => image.whoOriginal && image.whoOriginal.join(', ') === originalValues[0]),
                images.every(image => image.corpsOriginal && image.corpsOriginal.join(', ') === originalValues[1]),
                images.every(image => image.keywordsOriginal && image.keywordsOriginal.join(', ') === originalValues[2]),
            ]
            const stateImages = [...this.state.images]
            if (!allMatch[0]) {
                images.forEach(image => {
                    if (image.whoOriginal) {
                        if (image.whoOriginal && image.whoOriginal.length === 1 && image.whoOriginal[0] === '') {   
                            image.who = [...image.who]
                        } else {
                            image.who = [...image.whoOriginal, ...image.who]
                        }
                        const index = stateImages.findIndex(stateImage => stateImage.id === image.id);
                        stateImages[index] = image;
                    }
                })
            }
            if (!allMatch[2]) {
                images.forEach(image => {
                    if (image.corpsOriginal) {
                        if (image.corpsOriginal && image.corpsOriginal.length === 1 && image.corpsOriginal[0] === '') {   
                            image.corps = [...image.corps]
                        } else {
                            image.corps = [...image.corpsOriginal, ...image.corps]
                        }
                        const index = stateImages.findIndex(stateImage => stateImage.id === image.id);
                        stateImages[index] = image;
                    }
                })
            }
            if (!allMatch[2]) {
                images.forEach(image => {
                    if (image.keywordsOriginal) {
                        if (image.keywordsOriginal && image.keywordsOriginal.length === 1 && image.keywordsOriginal[0] === '') {   
                            image.keywords = [...image.keywords]
                        } else {
                            image.keywords = [...image.keywordsOriginal, ...image.keywords]
                        }
                        const index = stateImages.findIndex(stateImage => stateImage.id === image.id);
                        stateImages[index] = image;
                    }
                })
            }
            await this.setState({images: stateImages}, () => {
            });
        }
        console.log(ids);
        for (let id of ids) {

            //connecting to the database
            const db = new Database();

            //updating the image info
            if (this.state.originalTitle !== undefined) {
                await db.updateImage({
                    id,
                    title: this.state.title,
                })
            }
            if (this.state.informationChanged) {
                const image = this.state.images.find(image => image.id == id);
                if (image.keywords.includes(',')) {
                    image.keywords = this.commasToArray(image.keywords);
                    image.keywords.forEach(keyword => {
                        keyword.trim();
                    })
                }
                if (image.who.includes(',')) {
                    image.who = this.commasToArray(image.who);
                    image.who.forEach(one => {
                        one.trim();
                    })
                }
                if (image.corps.includes(',')) {
                    image.corps = this.commasToArray(image.corps);
                    image.corps.forEach(one => {
                        one.trim();
                    })
                }
                if (image.tags) {
                    if (image.tags.includes(',')) {
                        image.tags = this.commasToArray(image.tags);
                        image.tags.forEach(one => {
                            one.trim();
                        })
                    }
                }
                if (image.whoOriginal) {
                    await db.updateImage({
                        id,
                        who: image.who,
                    })
                } if (image.corpsOriginal) {
                    await db.updateImage({
                        id,
                        corps: image.corps,
                    })
                } if (image.keywordsOriginal) {
                    await db.updateImage({
                        id,
                        keywords: image.keywords,
                    })
                } if (image.tagsOriginal) {
                    await db.updateImage({
                        id,
                        tags: image.tags,
                    })
                }
            }
            console.log(this.state.isPublicOriginal);
            if (this.state.isPublicOriginal !== undefined) {
                console.log(this.state.isPublicOriginal);
                await db.updateImage({
                    id,
                    visible: this.state.isPublic,
                })
            }
        }

        //sheing the success message
        new Alert("נשמר", "השינויים נשמרו בהצלחה!");

        //getting the images again with the new content
        this.setState({
            selectedIds: [],
            ids: [],
            savebuttonDisabled: true,
        }, () => {
            if (this.state.newUpload) { 
                this.updateNewImages(); 
            } else if (!this.state.newUpload) {
                const db = new Database();
                    this.setState({images: []}, () => {
                    db.get("images", { collectionId: this.props.collectionId }).then((image) => {
                        // console.log(this.props);
                        // console.log(image);
                        //updated the "collectionId" so we can find the rest of the images in this collecion and edit them as well
                        this.setState({ collectionId: image[0].collectionId });

                        //getting images from the database, where the collectionId is equal to the id of image[0].collectionId
                        db.get("images", {
                            collectionId: image[0].collectionId,
                        }).then((collectionsImages) => {
                            //collectionsImages" is an array that contains all the results
                            collectionsImages.forEach((image) => {
                                //"image" is an object

                                //updating the images state with the result
                                let images = [...this.state.images];
                                images.push({
                                    url: image.image.url,
                                    id: image.id,
                                    title: image.title,
                                    uploader: image.uploader,
                                    who: image.who,
                                    corps: image.corps,
                                    keywords: image.keywords,
                                    tags: image.tags,
                                    thumbnail: image.thumbnail.url,
                                    cover: image.cover.url,
                                });
                                this.setState({ images }, () => {
                                    document.querySelector('body').scrollTop = this.state.imageGridScrollTop;
                                });

                                //updating the ids state with the result image's ids.
                                let ids = [...this.state.ids];
                                ids.push(image.id);
                                this.setState({ ids });

                                //Updating the form with the info that was recived
                                this.setState({ 
                                    title: image.title,
                                    isPublic: image.visible,
                                    uploader: image.uploader,
                                });
                            });
                        });
                    });
                })
            }
        })
        this.imageGrid.current.resetSelected();
        this.setState({
            title: '',
            uploader: '',
            who: '',
            corps: '',
            keywords: '',
            originalTitle: undefined, 
            isPublicOriginal: undefined,
            informationChanged: false,
            selection: true,
            imagesChanged: [],
            selected: false,
        })
        this.disableResetButton();
    }
    autoDetect = async () => {
        if (
            //Showing the confirmation message
            await new Confirm(
                "זהירות!",
                "מילוי המידע באופן אוטומטי יחליף את המידע הנוכחי. האם להמשיך?"
            ).show()
        ) {
            try {
                //storing the info on state
                this.setState({
                    keywords: this.state.autoDetectedData.description.tags.join(", "),
                });
                this.setState({
                    title: this.state.autoDetectedData.description.captions[0].hebrew,
                });
                this.setState({
                    who: this.state.autoDetectedData.faces,
                });
            } catch (e) {
                new Alert("אופס", "לא ניתן לבצע את הפעולה המבוקשת");
            }
        }
    }
    deleteCollection = async () => {
        //Showing the confirmation message
        const confirm = new Confirm(
            "בטוח?",
            "הפעולה תמחק את כל התמונות באוסף התמונות הנוכחי. זוהי פעולה שלא ניתן לשחזר."
        );
        const deleteConfiramtion = await confirm.show();
        //if the user has confirmed
        if (deleteConfiramtion) {
            //connecting to the databse
            const db = new Database();
            
            //deleting the images with collectionId of this.state.collectionId
            db.delete("images", { collectionId: this.state.collectionId }).then(res => {
                window.location.replace("/manage");
            }).catch(error => {
                console.log(error);
                new Alert(
                    'שגיאה!',
                    'אירעה תקלה בשרת, אנא נסה שנית מאוחר יות'
                )
            })

        }
    }
    openMergePanel = () => {
        if (this.state.merge) {
            document.querySelector('.merge-container').style.opacity = 0;
            setTimeout(() => {
                this.setState({merge: !this.state.merge});
            }, 500)
        } else {
            this.setState({merge: !this.state.merge});
        }
    }
    mergeCollection = async (id) => {
        let images = [...this.state.images];
        const confirm = new Confirm(
            `האם ברצונך למזג אוסף זה עם האוסף הנבחר?`,
            'פעולה זו תמזג אוסף זה עם האוסף הנבחר, זוהי פעולה שלא ניתן לשחזר',
            true,
        );
        const merge = await confirm.show();
        if (merge) {
            let title = undefined;
            let visible = false;
            const db = new Database();
            db.get('images', {collectionId: id}).then(res => {
                title = res[0].title;
                visible = res[0].visible;
                images.forEach(image => {
                    const imageId = image.id;
                    db.updateImage({
                        id: imageId,
                        collectionId: id, 
                        title,
                        visible,
                    }).then(res => {
                        new Alert(
                            'המיזוג הושלם בהצלחה!', 
                            '', 
                            false,  
                            '; window.open(`/manage`, `_self`)');
                    })
                })
            })
        }
    }
    deleteImage = (id) => {
        let tempImages = [...this.state.images];
        const images = tempImages.filter(image => image.id !== id)
        this.setState({images})
    }
    resetImages = async () => {
        let images = [...this.state.images];
        if (!this.state.selected) {
            images.forEach(image => {
                if (image.whoOriginal) {
                    image.who = image.whoOriginal;
                    delete image.whoOriginal;
                }
                if (image.corpsOriginal) {
                    image.corps = image.corpsOriginal;
                    delete image.corpsOriginal;
                }
                if (image.keywordsOriginal) {
                    image.keywords = image.keywordsOriginal;
                    delete image.keywordsOriginal;
                }
            })
            this.setState({
                images,
                informationChanged: false,
                selectedIds: [],
                selected: false,
                imagesChanged: [],
                who: '',
                corps: '',
                keywords: '',
            }, () => {
                this.checkForChanges();
            })
            this.imageGrid.current.setState({selected: []})
        } else {
            let imagesChanged = [...this.state.imagesChanged];
            let ids = [...this.state.selectedIds];
            ids.forEach(id => {
                let image = images.find(image => image.id === id);
                if (image.whoOriginal) {
                    image.who = image.whoOriginal;
                    delete image.whoOriginal;
                }
                if (image.corpsOriginal) {
                    image.corps = image.corpsOriginal;
                    delete image.corpsOriginal;
                }
                if (image.keywordsOriginal) {
                    image.keywords = image.keywordsOriginal;
                    delete image.keywordsOriginal;
                }
                const imageChangedIndex = imagesChanged.findIndex(image => image.id === id);
                if (imageChangedIndex) {
                    imagesChanged.splice(imageChangedIndex, 1);
                }
            })
            const boolean = imagesChanged.length > 0;
            this.setState({
                images,
                imagesChanged,
                informationChanged: boolean,
            }, () => {
                this.checkForChanges();
            })
            const editObject = images.filter(image => ids.includes(image.id));
            this.openEditForm(this.state.selectedIds, editObject)
        }
    }
    openEditForm = (selected, object) => {
        console.log(object)
        // console.log(selected, object);
        let whoMatch = undefined;
        let corpsMatch = undefined;
        let tagsMatch = undefined;
        let keywordsMatch = undefined;
        let selectedIds = [];
        whoMatch = object.every(item => {
            return item.who.toString() === object[0].who.toString()
        })
        corpsMatch = object.every(item => {
            return item.corps.toString() === object[0].corps.toString()
        })
        tagsMatch = object.every(item => {
            if (item.tags && object[0].tags)
            return item.tags.toString() === object[0].tags.toString()
        })
        keywordsMatch = object.every(item => {
            return item.keywords.toString() === object[0].keywords.toString()
        })
        if (selected.length === 0) {
            this.setState({
                who: '',
                corps: '',
                tags: '',
                keywords: '',
                selected: false,
            })
        } else if (selected.length === 1) {
            const keywords  = object[0].keywords.join(', ');
            const who  = object[0].who.join(', ');
            const corps = object[0].corps.join(', ');
            let tags = '';
            if (object[0].tags) {
                tags = object[0].tags.join(', ');
                console.log('dick suck')
            }
            this.setState({
                who,
                corps,
                tags, 
                keywords,
                selected: true,
                originalTitle: undefined,
            })
        } else if (selected.length > 1) {
            if (whoMatch) {
                const who  = object[0].who;
                if (typeof who !== 'string') {
                    who.join('-');
                }
                this.setState({
                    who,
                    selected: true,
                    originalTitle: undefined,
                })
            } else {
                this.setState({
                    who: '',
                    selected: true,
                    originalTitle: undefined,
                })
            }
            if (corpsMatch) {
                this.setState({
                    corps: object[0].corps,
                    selected: true,
                    originalTitle: undefined,
                })
            } else {
                this.setState({
                    corps: '',
                    selected: true,
                    originalTitle: undefined,
                })
            }
            if (tagsMatch) {
                this.setState({
                    tags: object[0].tags,
                    selected: true,
                    originalTitle: undefined,
                })
            } else {
                this.setState({
                    tags: '',
                    selected: true,
                    originalTitle: undefined,
                })
            }
            if (keywordsMatch) {
                const keywords  = object[0].keywords.join(', ');
                this.setState({
                    keywords,
                    selected: true,
                    originalTitle: undefined,
                })
            } else {
                this.setState({
                    keywords: '',
                    selected: true,
                    originalTitle: undefined,
                })
            }
        }
        object.forEach(item => {
            selectedIds.push(item.id)
        })
        this.setState({selectedIds}, () => {
            this.disableResetButton();
        })
    }
    updateNewImages = async () => {
        this.imageGrid.current.setState({blockImages: false})
        document.querySelector('.ready-button button').innerHTML = 'טוען...';
        const db = new Database();
        db.get("images", {collectionId: this.state.collectionId}).then(result => {
            if(result.length !== this.state.images.length) {
                setTimeout(() => {
                    this.updateNewImages();
                }, 2000);
                return;
            } else {
                this.setState({
                    images: [],
                    ids: []
                }, () => {
                    let images = [];
                    let ids = [];
                    result.forEach(image => {
                        images.push({
                            id: image.id,
                            title: image.title,
                            uploader: image.uploader,
                            who: image.who,
                            corps: image.corps,
                            keywords: image.keywords,
                            url: image.image.url,
                        });
                        ids.push(image.id)
                    })
                    this.setState({images, ids}, () => {
                        document.querySelector('.ready-button button').innerHTML = 'מוכן!';
                        document.querySelector('.ready-button button').style.backgroundColor = 'rgb(17, 146, 60)'
                    })
                })
            }
        })

    }
    reloadToCollection = () => {
        window.location.replace(`/manage/edit/${this.state.collectionId}`)
    }
    render() {
        const query = {};
        return (
            <React.Fragment>
                <Helmet>
                    <title>המאגר | עריכת תמונה</title>
                </Helmet>
                {this.state.merge ? <div className='merge-container'>

                    <div className='background' onClick={this.openMergePanel}></div>
                    <div className='merge-panel'>
                        <h1>בחר אוסף למזג</h1>
                        <ImageList
                            search={this.state.search}
                            onImageClick="merge"
                            hidePreview={true}
                            showHidden={true}
                            showInfo={true}
                            query={query}
                            mergeCollection={(id) => this.mergeCollection(id)}
                        ></ImageList>
                    </div>
                </div> : ''}
                <div
                    className="background-div"
                    style={{backgroundColor: this.state.selectedBackgroundColor}}
                >
                    <div className="upload-actions">
                        {this.state.isCommander ? (
                            <div className={`edit-mode-radio`}>
                                <div className="edit-mode-title">סטטוס נראות</div>
                                <input type="radio" name="seen" id="seen1" value="true" />
                                <label
                                    htmlFor="seen1"
                                    className={`radio-label${this.state.isPublic ? " active" : ""}`} onClick={() => this.isPublicChangeHandler(true)}>ציבורי</label>
                                <input type="radio" name="seen" id="seen2" value="false" />
                                <label
                                    htmlFor="seen2"
                                    onClick={() => this.isPublicChangeHandler(false)}
                                    className={`radio-label${this.state.isPublic ? "" : " active"
                                        } `}
                                >
                                    מוסתר
                </label>
                            </div>
                        ) : null}
                        {/* this.state.ids.length === 0 || || this.state.newUpload || this.state.selectedIds.length < 1 || this.state.savebuttonDisabled */}
                        {this.state.edit ? (
                            <div>
                                <button className="deleteBtn" disabled={this.state.ids.length < 1} onClick={this.deleteCollection}>מחיקת אוסף</button>
                                <button className="mergeBtn" onClick={this.openMergePanel}>מיזוג</button>
                            </div>
                        ) : ""}
                    </div>
                </div>

                <div className="upload-area">
                    <div className={`right-side ${this.state.newUpload ? 'disabled' : ''}`}>
                        <button className="save" disabled={this.state.savebuttonDisabled} onClick={this.saveImages }>שמירה</button>

                        <form>
                            <div className="section-title">מידע כללי
                                {Object.keys(this.state.autoDetectedData).length > 0 ? (
                                    <span
                                        className={`auto${this.state.autoDetectedData.length === 0 ? " none" : ""
                                            } `}
                                        onClick={this.autoDetect}
                                    >
                                        זיהוי אוטומטי
                                    </span>
                                ) : null}
                            </div>
                            <label htmlFor="title">כותרת</label>
                            <input
                                type="text"
                                name="title"
                                id="title"
                                value={this.state.title}
                                onChange={(e) => this.titleChangeHandler(e.target.value)}
                                readOnly={this.state.selected}
                            />
                            <label htmlFor="uploader">מעלה האוסף</label>
                            <input
                                type="text"
                                name="uploader"
                                id="uploader"
                                value={this.state.uploader}
                                readOnly
                            />
                            <span className="section-title">קטלוג</span>
                            <label htmlFor="who">מי בתמונה?</label>
                            <input
                                type="text"
                                name="who"
                                id="who"
                                placeholder={this.state.selectedIds.length === 0 ? 'בחר תמונות על מנת להציג מידע' : (this.state.selectedIds.length > 1 && this.state.who.length < 1 ? 'ערכים אשר יתפווספו לערכים הקיימים' : "‎ערכים מרובים מופרדים בפסיקים")}                                
                                value={this.state.who}
                                onChange={(e) => this.whoChangeHandler(e.target.value)}
                                readOnly={this.state.selectedIds.length === 0}
                            />
                            <label htmlFor="corps">שיוך חיילי</label>
                            <input
                                type="text"
                                name="corps"
                                id="corps"
                                placeholder={this.state.selectedIds.length === 0 ? 'בחר תמונות על מנת להציג מידע' : (this.state.selectedIds.length > 1 && this.state.corps.length < 1 ? 'ערכים אשר יתפווספו לערכים הקיימים' : "‎ערכים מרובים מופרדים בפסיקים")}
                                value={this.state.corps}
                                onChange={(e) => this.corpsChangeHandler(e.target.value)}
                                readOnly={this.state.selectedIds.length === 0}
                            />
                            <label htmlFor="corps">תגיות</label>
                            <input
                                type="text"
                                name="tags"
                                id="tags"
                                placeholder={this.state.selectedIds.length === 0 ? 'בחר תמונות על מנת להציג מידע' : (this.state.selectedIds.length > 1 && this.state.tags.length < 1 ? 'ערכים אשר יתפווספו לערכים הקיימים' : "‎ערכים מרובים מופרדים בפסיקים")}
                                value={this.state.tags}
                                onChange={(e) => this.tagsChangeHandler(e.target.value)}
                                readOnly={this.state.selectedIds.length === 0}
                            />
                            <label htmlFor="keywords">מילות מפתח</label>
                            <textarea
                                type="text"
                                name="keywords"
                                id="keywords"
                                placeholder={this.state.selectedIds.length === 0 ? 'בחר תמונות על מנת להציג מידע' : (this.state.selectedIds.length > 1 && this.state.keywords.length < 1 ? 'ערכים אשר יתפווספו לערכים הקיימים' : "‎ערכים מרובים מופרדים בפסיקים")}
                                value={this.state.keywords}
                                onChange={(e) => this.keywordsChangeHandler(e.target.value)}
                                readOnly={this.state.selectedIds.length === 0}
                            ></textarea>
                        </form>
                    </div>
                    <div className="left-side">
                        <span className="section-title">תמונות</span>
                        <div className="upload-button" onClick={this.openFileDialog}>
                            העלאת תמונה
                            <span className="upload-icon"></span>
                        </div>
                        <input
                            type="file"
                            id="ufile"
                            name="file"
                            onInput={this.uploadFile}
                            multiple="multiple"
                            className="hide"
                            accept="image/gif, image/jpeg, image/png"
                        ></input>
                        {this.state.selectedFilesCount > 0 ? (
                            <UploadProgress
                                title={`${this.state.newUpload ? this.state.ids.length : this.state.ids.length - this.state.existingImages} קבצים עלו מתוך ${this.state.selectedFilesCount}`}
                                max={this.state.selectedFilesCount}
                                min="0"
                                progress={this.state.newUpload ? this.state.ids.length : this.state.ids.length - this.state.existingImages}
                                readyToEdit={this.state.readyToEdit}
                                readyButton={this.updateNewImages}
                                newUpload={this.state.newUpload}
                                existingImages={this.state.existingImages}
                                reloadToCollection={this.reloadToCollection}
                                readyToReload={this.state.readyToReload}
                            />
                        ) : null}
                        <ImageGrid ref={this.imageGrid} openEditForm={(selected, object) => this.openEditForm(selected, object)} images={this.state.images} newUpload={this.state.newUpload} changeSelection={(selection) => this.changeSelection(selection)} selection={this.state.selection} cancelMultipleSelection={this.cancelMultipleSelection} deleteImage={(id) => this.deleteImage(id)} resetButtonDisabled={!this.state.resetButtonDisabled} resetImages={this.resetImages}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UploadForm;
