import React, { Component } from "react";
import "../../css/main.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "../../components/header";
import Auth from "../../src/auth";
import Alert from "../../src/alert";
import LoadingScreen from "../../src/loading";
import { Helmet } from "react-helmet";
import Redirect from "../../src/redirect";

class Login extends Component {

  state = { username: "", password: "" };

  //Handeling the Login button click
  handleClick(event) {
    event.preventDefault();

    //Calling the auth class, wich have login function in it
    const auth = new Auth();

    //Logging the user in with the provided data
    auth.login(this.state.username, this.state.password).then(res => {
      if (res.status === 200) {
        //If the data is correct, logging the user in
        new Redirect('../');
      } else {
        //If not, showing the problem in an alert
        new Alert('אופס!', 'שם משתמש או סיסמא לא נכונים');
      }
    });
  }
  forgotPassword = (e) => {
      e.preventDefault();
      new Alert('שכחתי סיסמא', 'על מנת לשחזר סיסמא יש לפנות למנהלי המערכת');
  }
  async componentDidMount() {
    //Show Loading screen
    const loadingScreen = new LoadingScreen();
    loadingScreen.startLoading();

    //Checking if the user is already logged in    
    const auth = new Auth();
    const permisstion = await auth.hasPermission('admin').catch(e => new Alert(e));
    if (permisstion) {
      new Redirect('../', 'replace');
    }
    else {
      loadingScreen.stopLoading();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>התחברות</title>
        </Helmet>
        <Header title="התחברות" link={false} />
        <div className="login">
          <form onSubmit={e => this.handleClick(e)}>
            <label for="phone">מספר נייד</label>
            <input
              type="text"
              id="phone"
              name="phone"
              onInput={e => this.setState({ username: e.target.value })}
            ></input>
            <label for="password">סיסמה</label>
            <input
              type="password"
              id="password"
              name="password"
              onInput={e => this.setState({ password: e.target.value })}
            ></input>
            <button>התחברות</button>
            <Link to="register">הרשמה</Link><br />
            <button onClick={(e) => this.forgotPassword(e)} style={{margin: '0', fontWeight: '400', color: 'rgb(118, 179, 236)'}}>שכחת סיסמא?</button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
