import React, { Component } from 'react';
import Header from '../components/header';
import ImageList from '../components/imageList';
import Footer from '../components/footer';
import Helmet from 'react-helmet';
import Database from '../src/database';

class Feedback extends Component {
    state = {
    }
    componentDidMount() {
    }

    render() {
        return (
            // <div className='feedback'
            <React.Fragment>
                <Helmet>
                    <title>
                        המאגר | {`בקשת ויזואליה`}
                    </title>
                </Helmet>
                <Header title="השארת פידבק" link={true} customLink="../../" search={false} small={true} searchedText={this.props.match.params.searchQ} noLogo={true} back={true}/>
                <div className="page-content request-container">
                    <iframe className='request-form' src='https://docs.google.com/forms/d/e/1FAIpQLSdyy2FxJ0jbukxQ5m8FXeBXJpYtnjxXqQGadnz3IqDba-rUvg/viewform'></iframe>
                </div>
                <Footer></Footer>
            </React.Fragment>
        );
    }
}

export default Feedback;