import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Redirect from '../src/redirect';

class DashboardButton extends Component {
    state = {
        path: '/assets/images/icons/',
        extention: ".svg"
    }
    render() {
        return (<React.Fragment>
            <div className="dashboard-button" onClick={() => { new Redirect(this.props.link) }}>
                <img src={this.state.path + this.props.icon + this.state.extention} alt="icon" />
                <div className="button-title">{this.props.title}</div>
            </div>
        </React.Fragment>);
    }
}

export default DashboardButton;