
import Urls from "./url";
const axios = require('axios').default;

class Auth {
    nexyYear;
    previousYear;
    token = '';
    userObject;
    constructor() {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var c = new Date(year + 1, month, day);
        this.nexyYear = c;
        this.token = this.getCookie();
    }

    register(userObject) {
        return new Promise(resolve => {

            axios.post(
                `${new Urls().getBaseUrl()}/register`,
                userObject,
                { headers: { 'Content-Type': 'application/json' } }
            ).then(response => {
                if (response.data == 'phone') {

                    resolve({ status: 500, message: 'מספר הפלאפון כבר קיים במערכת' });
                } else if (response.data == 'email') {

                    resolve({ status: 500, message: 'האימייל כבר קיים' });
                } else {
                    resolve({ status: 200, message: 'done' });
                }
            }).catch(err => resolve({ status: 100, message: err }));
        });
    }

    logOut() {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.reload();
    }

    login(username, password) {
        return new Promise(resolve => {
            axios.post(
                `${new Urls().getBaseUrl()}/login`,
                {username, password},
                { headers: { 'Content-Type': 'application/json' } }
            ).then(response => {
                if (response.data.status === 200) {
                    document.cookie = `token=${response.data.token}; expires=${this.nexyYear}; path=/;`;
                    resolve({ status: 200, message: 'done' });
                } else {
                    resolve({ status: 10, message: 'wrong details' });
                }
            }).catch(err => resolve({ status: 100, message: err }));
        })
    }

    async user() {
        const bearer = `Bearer ${this.token}`;
        if (this.token !== '') {
            const response = await axios.post(
                `${new Urls().getBaseUrl()}/getuser`,
                {},
                { headers: { 'Content-Type': 'application/json', 'Authorization': bearer } }
            );
            this.userObject = response.data.data;
            return this.userObject;
        }
    }

    async hasPermission(permissionToCheck = 'read') {
        const user = await new Auth().user();
        if (typeof user === 'undefined') {
            return false;
        }
        else if (user.permissions.split(',').includes(permissionToCheck)) {
            return true;
        }
        else {
            console.log(user.permissions.split(','), user.permissions.split(',').includes(permissionToCheck).toString() + ' - ' + permissionToCheck);
            return false;
        }
    }

    isLogged() {
        if (this.token != '') {
            return true;
        } else {
            return false;
        }
    }

    getCookie(cname = 'token') {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

}

export default Auth;