import React, {Component} from 'react';
import {Button} from '@material-ui/core';

class Terms extends Component {
    state={
        checked: false,
    }

    changeChecked = () => {
        this.setState({checked: !this.state.checked})
    }
    buttonHandler = () => {
        document.querySelector('.terms').style.opacity = '0';
        setTimeout(() => {
            this.props.termsAgreed();
        }, 400)
    }
    render() {
        return(
            <div className='terms'>
                <div className='black'></div>
                <div className='terms-window'>
                    <h1>תקנון שימוש במאגר</h1>
                    <div className='terms-inner-window'>
                        <p>
                        <h3>תקנון לשימוש במאגר התמונות והסרטונים באתר צה"ל עדכניים ליום 01.01.2021</h3>
                        <h3>כללי</h3>
                        •	מאגר התמונות והסרטונים של דובר צה"ל  (להלן – "המאגר") המתפרסם באתר צה"ל, נועד לאפשר גישה לציבור הרחב לתמונות וסרטונים (להלן – "יצירות") אשר צולמו ע"י המשרתים בצה"ל במסגרת תפקידם, ואשר משרד הביטחון וצה"ל הם בעלי זכות היוצרים בהם. 
                        <br />
                        <br />
                        <h3>שימוש ביצירות הכלולות במאגר</h3>
                        •	כל הזכויות ביצירות הכלולות במאגר שמורות למשרד הביטחון ולצה"ל. לא ניתן להעתיק יצירות אלה או לעשות בהן כל שימוש, אלא בהתאם לתנאי הרישיון המפורטים מטה או לפי כל דין.<br />
                        •	משרד הביטחון וצה"ל מעניקים בזאת רישיון שימוש כלל-עולמי, ללא תמלוגים, לא בלעדי, בלתי מוגבל בזמן לציבור הרחב ביצירות, בכפוף לתנאים הבאים:<br />
                        •	ניתן להעתיק יצירה, להפיץ עותקים ממנה, להעמידה לרשות הציבור, לשדרה ולבצעה בפומבי, ובלבד שאין בכך משום סילוף, פגיעה, השחתה, שינוי או כל ביצוע פעולה אחרת, לרבות שימוש בהקשר כלשהו, שיש בהם משום הפחתת ערך ביחס ליצירה או העלולים, לדעת משרד הביטחון או צה"ל, לפגעו בכבודם או בשמם של משרד הביטחון או צה"ל או המצולמים או כולם יחד. סעיף זה יחשב כמופר עם הודעת משרד הביטחון או צה"ל כי לדעתם מתקיימים אחד או יותר מהמעשים כאמור לעיל, בגין שימוש שנעשה ביצירה.<br />
                        •	ניתן לעשות שימוש בכל אחת מן היצירות הכלולות במאגר, אבל לא בכולן יחד. <br />
                        •	השימוש ביצירות יעשה תוך מתן קרדיט לדובר צה"ל, כך שבצמוד לכל יצירה, יהיה כתוב בבירור, "צילום: דובר צה"ל". <br />
                        •	חל איסור על עשיית יצירה נגזרת, לרבות עריכת התמונות והסרטונים במאגר, כך שיש להשתמש בהם כפי שהם מוצגים במאגר, ללא כל שינוי או עריכה. זאת, לרבות חיתוך או שימוש רק בחלק מהם. <br />
                        •	חל איסור על שימוש ביצירות בצורה שמפרה את החוק.<br />
                        •	חל איסור על שימוש ביצירות לצורכי התרמה, לצורכי פרסומת מסחרית מכל סוג - לרבות קידום מוצרים, שירותם או דבר-מה אחר – או לצורכי פעילות מפלגתית.  <br />
                        <br />
                        <h3>מצגים, אחריות והסרת אחריות</h3>
                        •	השימוש ביצירות כפוף לדיני זכויות היוצרים התקפים בישראל. <br />
                        •	משרד הביטחון ו/או צה"ל ו/או מי מטעמם לא יישאו באחריות לכל נזק ו/או אובדן מכל סוג ומין שהוא לרבות לנזק כלכלי תוצאתי שייגרם למשתמש במאגר ו/או למי מטעמו, בכל סיבה שהיא ובכל נסיבות שהן, תוך כדי ו/או עקב ו/או כתוצאה ו/או בקשר עם השימוש במאגר. <br />
                        •	משרד הביטחון ו/או צה"ל ו/או מי מטעמם לא יישאו באחריות להתאמת רישיון השימוש לצורכי המשתמש. <br />
                        •	משרד הביטחון ו/או צה"ל ו/או מי מטעמם לא יישאו באחריות לטעויות או לשגיאות בחומר המוצג במאגר. המשתמש בלבד יישא באחריות לאופן שבו הוא עושה שימוש ביצירות. <br /> 
                        •	משרד הביטחון ו/או צה"ל ו/או מי מטעמם לא יישאו באחריות לשינויים שנעשו בחומר המוצג ברישיון על-ידי משתמש או על ידי כל צד ג'.<br />
                        •	משרד הביטחון ו/או צה"ל ו/או מי מטעמם לא יישאו באחריות כלפי צד ג', הנובעת משימוש ברישיון או שימוש ביצירות שבמאגר. <br />
                        •	רישיון השימוש מוצע לציבור "כמו שהוא" ("As Is"). השימוש ברישיון נעשה על אחריות המשתמש בלבד.  <br />
                        •	משרד הביטחון או צה"ל שומרים על זכותם לשנות את רישיון השימוש בכל עת ולבטל רישיון שימוש כזה או אחר. מיד לאחר קבלת הודעה על ביטול רישיון השימוש, על המשתמש להפסיק באופן מידי את השימוש.  <br /> 
                        •	המשתמש במאגר מצהיר כי השימוש במאגר הינו באחריותו המלאה והבלעדית וכי לא תהא לו כל טענה ו/או דרישה ו/או תביעה כנגד משרד הביטחון ו/או צה"ל ו/או מי מטעמם בגין נזק ו/או אובדן כאמור ומשרד הביטחון ו/או צה"ל ו/או מי מטעמם פטורים מכל אחריות בגינם.<br />
                        •	משרד הביטחון או צה"ל שומרים על זכותם להוריד יצירות מהמאגר בכל עת.<br />


                        </p>
                        <div className='transition-bar'></div>
                    </div>
                    <div>
                        <input type='checkbox' id='checkbox' checked={this.state.checked} onChange={this.changeChecked}></input>
                        <label htmlFor='checkbox'>קראתי ואני מאשר/ת את תנאי השימוש</label>
                    </div>
                    <Button variant='contained' disabled={!this.state.checked} onClick={this.buttonHandler}>אישור</Button>
                </div>
            </div>
        )
    }
}

export default Terms;