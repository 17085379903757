import React, { Component } from 'react';

class LoadingBar extends Component {
    state = {
        min: 0,
        max: 100,
        progress: 0,
        currentProgress: 50,
        newUpload: true,
        ...this.props
    }

    componentDidMount() {

        if (this.state.progress === 0) {
            this.setState({ currentProgress: 0 });
        } else {
            const per = Math.round((this.state.progress / (this.state.max - this.state.min)) * 100);
            this.setState({ currentProgress: per });
        }
    }

    componentWillReceiveProps(nextProp) {
        if (typeof nextProp.min !== 'undefined') this.setState({ min: parseInt(nextProp.min) });
        if (typeof nextProp.max !== 'undefined') this.setState({ max: parseInt(nextProp.max) });
        if (typeof nextProp.progress !== 'undefined') {
            if (nextProp.progress === 0) {
                this.setState({ currentProgress: 0 });
            } else {
                const per = Math.round((nextProp.progress / (this.state.max - this.state.min)) * 100);
                this.setState({ currentProgress: per });
            }
        }
        if (!nextProp.newUpload) {
            this.setState({newUpload: false})
        }
    }

    render() {
        if (this.state.newUpload || this.props.readyToReload) {
            if (this.state.currentProgress === 100) {
                this.props.reloadToCollection();
            }
        }
        return (<React.Fragment>
            <div className="progress-box">
                <div className="file-name">{this.props.title}</div>
                <div className="progress" style={{ width: `${this.state.currentProgress}%` }}>{`${this.state.currentProgress}%`}</div>
                <div className="progress-area" ></div>
            </div>
        </React.Fragment >);
    }
}

export default LoadingBar;