import React, { Component } from 'react';
import Header from '../components/header';
import ImageList from '../components/imageList';
import Footer from '../components/footer';
import qs from 'qs';

class Search extends Component {
    state = {
        search: this.props.match.params.searchQ,
        advancedProps: {
            including: '',
            notIncluding: '',
        }
    }

    componentDidMount() {
        const advancedProps = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
        this.setState({advancedProps})
    }
    doubleSearch = () => {

    }
    render() {
        return (<React.Fragment>
            <Header title="" link={true} customLink="../../" search={true} small={true} searchedText={this.props.match.params.searchQ} searchUrl="../../search" advancedProps={this.state.advancedProps}/>
            <div className="body">
                <ImageList itemIndex={0} loadingText="מחפש..." search={this.props.match.params.searchQ} onImageClick="/show" collectImages={false} show={true} advancedProps={this.state.advancedProps}></ImageList>
            </div>
            <Footer></Footer>
        </React.Fragment>);
    }
}

export default Search;