class LoadingScreen {
    startLoading(text = 'טוען') {
        const rootElememt = document.querySelector('#root');
        const loadScreen = document.createElement('div');
        loadScreen.setAttribute('id', `loading`);
        loadScreen.classList.add('loading');
        loadScreen.innerHTML = `<div class="loading-elements"><img src="/assets/images/logo/dotz/black.svg"></img><div class="loading-text">${text}</div></div>`;
        rootElememt.append(loadScreen);
    }
    stopLoading() {
        if (document.querySelectorAll('#loading').length > 0) {
            document.querySelector('#loading').classList.add('done');
        }
        setTimeout(() => {
            if (document.querySelectorAll('#loading').length > 0) {
                document.querySelector('#loading').remove();
            }
        }, 500);
    }
}
export default LoadingScreen;