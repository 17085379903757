import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Helmet from 'react-helmet';

class ContentPage extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        המאגר | {this.props.title}
                    </title>
                </Helmet>
                <Header title={this.props.title} link={true} customLink='../' small={true} noLogo={this.props.noLogo} back={true}></Header>
                <div className="page-content" >
                    {this.props.children}
                </div>
                <Footer></Footer>
            </React.Fragment>
        );
    }
}

export default ContentPage;