import React from 'react';
let modalId = new Date().getTime();
class Alert {
    constructor(title, content, noButton, buttonExtraFunction, refresh) {
        const toRefresh = refresh;
        const rootElememt = document.querySelector('#root');
        const modal = document.createElement('div');
        modal.setAttribute('id', `alert`);
        modal.classList.add('alert');
        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }

        modal.innerHTML = `<div class="black"></div><div class="modal"><div class="title">${title}</div><div class="content">${content}</div>${!noButton ? `<button onclick="hideModal(this)${buttonExtraFunction ? buttonExtraFunction : ''}">סגור</button>` : ''}</div>`;
        if (document.querySelectorAll('#alertscript').length === 0) {
            const script = document.createElement('script'); 
            script.setAttribute('id', `alertscript`);
            script.innerHTML = `
                const hideModal = element => {
                    element.parentNode.parentNode.classList.remove('show');
                    setTimeout(() =>{
                        console.log('?')
                        element.parentNode.parentNode.remove();
                    } ,600);
                }
            `;
            rootElememt.append(script);
        }
        setTimeout(() => {
            document.querySelector('#alert').classList.add('show');
        }, 20);

        rootElememt.append(modal);
    }
}

export default Alert;