import React, { Component } from 'react';
import Header from '../components/header';
import ImageList from '../components/imageList';
import Footer from '../components/footer';
import Helmet from 'react-helmet';
import Database from '../src/database';

class Feedback extends Component {
    state = {
        nameInput: '',
        queryInput:'',
    }
    inputChange = (e) => {
        const type = e.target.name;
        if (type === 'name') {
            this.setState({nameInput: e.target.value})
        } else if (type === 'query') {
            this.setState({queryInput: e.target.value})
        }
    }
    submitForm = () => {
        document.querySelector('.form-area [name="name"]').classList.remove('form-input-empty');
        document.querySelector('.form-area [name="query"]').classList.remove('form-input-empty');
        if (this.state.nameInput === '' || this.state.queryInput === '') {
            if (this.state.nameInput === '') {
                console.log('name: ' + this.state.nameInput);
                document.querySelector('.form-area [name="name"]').classList.add('form-input-empty');
            }
            if (this.state.queryInput === '') {
                console.log('query: ' + this.state.queryInput);
                document.querySelector('.form-area [name="query"]').classList.add('form-input-empty');
            }
            document.querySelector('.missing-input').style.display = 'block';
            return;
        }
        document.querySelector('.missing-input').style.display = 'none';
        document.querySelector('.form-area').style.display = 'none';
        document.querySelector('.form-loading').style.display = 'block';
        const db = new Database();
        const object = {
            id: Math.random().toString().replace(/0\./, ""),
            name: this.state.nameInput,
            query: this.state.queryInput,
        }
        db.create('Feedback', object).then(res => {
            console.log(res)
            document.querySelector('.form-loading').style.display = 'none';
            document.querySelector('.form-thanks').style.display = 'block';
        }).catch(error => {
            document.querySelector('.form-loading').style.display = 'none';
            document.querySelector('.form-thanks').style.display = 'none';
            document.querySelector('.form-error').style.display = 'block';
            console.log(error);

        })
    }
    componentDidMount() {
    }

    render() {
        return (
            // <div className='feedback'
            <React.Fragment>
                <Helmet>
                    <title>
                        המאגר | {`השארת פידבק`}
                    </title>
                </Helmet>
                <Header title="השארת פידבק" link={true} customLink="../../" search={false} small={true} searchedText={this.props.match.params.searchQ} noLogo={true} back={true}/>
                <div className="page-content">
                    <div className='form-area'>
                        <form className='feedback-form' onSubmit={this.submitForm}>
                            <label htmlFor='name'>שם מלא:</label>
                            <input type='text' name='name' onChange={(e) => this.inputChange(e)}></input>
                            <label htmlFor='query'>טקסט:</label>
                            <textarea name='query' onChange={(e) => this.inputChange(e)}></textarea>
                        </form>
                        <button className='form-submission-button' onClick={this.submitForm}>שליחה</button>
                    </div>
                    <div className='missing-input'>
                        <h2>נא להשלים את הפרטים החסרים</h2>
                    </div>
                    <div className='form-loading'>
                        <h1>טוען...</h1>
                    </div>
                    <div className='form-thanks'>
                        <h1>תודה רבה!</h1>
                    </div>
                    <div className='form-error'>
                        <h1>אירעה תקלה בשרת, אנא נסה שוב מאוחר יותר</h1>
                    </div>
                </div>
                <Footer></Footer>
            </React.Fragment>
        );
    }
}

export default Feedback;