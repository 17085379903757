import React, { Component } from 'react';
import Terms from '../src/terms';

class Footer extends Component {
    state = {
        color: "white",
        which: "dotz",
        path: "/assets/images/logo",
        extention: ".svg",
        backgroundColor: 'rgb(0, 18, 41)'
    }
    render() {
        return (
            <React.Fragment>
                <div className="footer" style={{ backgroundColor: (typeof this.props.backgroundColor !== 'undefined') ? this.props.backgroundColor : this.state.backgroundColor }}>
                    <div className="wrapper">
                    <div className="logo">
                            <img className={(this.state.small || this.state.search) ? 'small' : ''}
                                src={
                                    this.state.path +
                                    "/" +

                                    this.state.which +
                                    "/" +
                                    this.state.color +
                                    this.state.extention
                                }
                            ></img>
                            <div className="text">
                                &copy; כל הזכויות שמורות
                                <br />
                                מערך דובר צה״ל {new Date().getFullYear()}.
                            </div>
                        </div>
                        <div className="line">
                            <div className="title">
                                קישורים חיוניים
                            </div>
                            <ul>
                                <a href="../../contact">
                                    <li>יצירת קשר</li>
                                </a>
                                <a href="/request">
                                    <li>לא מצאת את מה שחיפשת?</li>
                                </a>
                                <a href='../../feedback'>
                                    <li>השארת פידבק</li>
                                </a>
                                <a onClick={() => this.props.openTerms()}>
                                    <li>תנאי שימוש</li>
                                </a>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Footer;
